.news-separator {
    padding-top: 20px;
}

.newscard-wrapper {
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    border-radius: 12px;
    /* border: 1px solid #8e97cd00; */
    padding: 10px;
    /* width: 24%; */
    transition: all .25s;
    /* cursor: pointer; */
}

.newscard-wrapper:focus,
.newscard-wrapper:hover,
.newscard-wrapper:active {}

.nre {
    /*margin-top: -50px !important;*/
}

.news-shadow {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.news-date {
    position: absolute;
    bottom: 5px;
    left: 5px;
}

.newsimg {
    border-radius: 12px 12px 0px 0px;
    height: 100px;
    width: 100%;
    object-fit: cover;
}

.newsimg2 {
    border-radius: 12px;
    height: 150px;
    /* width: 100%; */
    object-fit: fill;
}

.ncarrow {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0px;
    bottom: -5px;
}

.nc-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #b5bbdf;
    position: relative;
    bottom: 12px;
}

.nc-hot-trend {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #f09c3a;
    text-shadow: 0px 2px 4px rgba(240, 156, 58, 0.4);
    display: flex;
    gap: 10px;
    align-items: center;
}

.nc-date {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.018em;
    color: #c1ccf8;
    display: flex;
    gap: 5px;
    align-items: center;
}

@media screen and (max-width: 991px) {
    .newscard-wrapper {
        width: 100% !important;
    }

    .newsimg2 {
        width: 100% !important;
    }
}

.top-pools-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    width: fit-content;
    letter-spacing: -0.018em;
    color: rgba(247, 247, 252, 0.8);
    padding: 0;
}

.main-dashboard-container {
    border-radius: 8px;
}

.regular-news {
    width: 49%;
}

.view-more-title {
    width: fit-content;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #4ED5D2 !important;
    cursor: pointer;
}

.toppool-allwrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.view-more-title:hover,
.view-more-title:focus {
    text-decoration: underline;
}

.header {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.018em;
    color: #f7f7fc;
    opacity: 0.8;
}

.faq-grid {
    display: grid;
    grid-template-columns: auto;
    gap: 13px;
}

@media screen and (max-width: 1399px) {
    .faq-grid {
        grid-template-columns: auto auto;
    }

    .right-side-wrapper {
        align-items: flex-end;
    }

    .faq-items-wrapper {
        width: 49%;
    }

    .chainlink-wrapper {
        width: 24%;
    }

    .launchpad-section-wrapper {
        width: 24%;
    }
}

@media screen and (max-width: 992px) {
    .regular-news {
        width: 100%;
    }
}


@media screen and (max-width: 786px) {
    .faq-items-wrapper {
        width: 100% !important;
    }

    .chainlink-wrapper {
        width: 100% !important;
    }

    .launchpad-section-wrapper {
        width: 100% !important;
    }

    .faq-grid {
        grid-template-columns: auto;
    }

    .toppool-allwrapper {
        grid-template-columns: auto;
    }

    .mobile-show {
        display: block;
    }

    .mobile-hide {
        display: none;
    }
}

@media screen and (min-width: 787px) {
    .desktop-hide {
        display: none;
    }
}
