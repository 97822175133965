@media (max-width:600px) {
    .earn-option {
        width: 105px;
    }

}

.earn-option-active {
    color: white !important;
    font-weight: 600;
}

.total-value-locked-container {
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;
    border: 1px solid rgb(146 155 152 / 50%);
}

.bnb-item-active {
    background: linear-gradient(277.84deg,#ddad0c .89%,#1e1c40 92.83%);
    border: 2px solid #dcad0c;
    box-shadow: 0 2px 12px rgb(220 173 12 / 22%);
}