/* .col-10 {
    margin-left: 100px;
} */

.explorercard-wrapper {
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;
    border: 1px solid rgb(146 155 152 / 50%);
}

.explorercard-wrapper:hover {
    border: 1px solid white;   
}

.bridgecard-wrapper:hover {
    border: 1px solid white;   
}

.govcard-wrapper:hover {
    border: 1px solid white;   
}

.govcard-wrapper {
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;
    border: 1px solid rgb(146 155 152 / 50%);
}

.bridgecard-wrapper {
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;
    border: 1px solid rgb(146 155 152 / 50%);
}

.calculator-wrapper {
    /* background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121   ;
    border: 1px solid rgb(146 155 152 / 50%); */
    /* background: linear-gradient(136deg, rgb(205 205 205 / 15%), rgb(114 113 113 / 21%) .01%, rgb(30 30 30 / 15%)), #212121; */
    border: 1px solid rgb(131 143 139 / 50%);
    background-color: #212121;
}

.calculator-wrapper:hover {
    border: 1px solid white; 
}

.newscard-wrapper:hover {
    border: 1px solid white;
}

.staking-calc-text {
    font-weight: bold;
    font-size: 16px;
}
