@media (max-width:768px
) {
    .col-11{
    flex: 0 0 auto !important;
    width: 100% !important;
   
}
.pcc{
     padding-right: 0.25rem!important;
    padding-left: 0.25rem!important;
}
    
}