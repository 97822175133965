.whopping-roi {
    font-size: 22px !important;
    font-weight: bold;
}

.money-smell {
    color: #85bb65 !important;
}

.dashboard-stat-title {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    color: white;
    font-weight: 600;
    font-size: 16px;
}

.dashboard-stat {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: white;
    font-size: 20px;
}

.dashboard-stats {
    margin-top: -50px;
}

.dashboard-card {
    background: #212121 !important;
    /* border: 1px solid white; */
    border: 1px solid rgb(146 155 152 / 50%) !important;
    border-radius: 8px;
    height: 80px;
    width: 235px;
}

.myearnings-card {
    background: #212121 !important;
    /* border: 1px solid white; */
    border: 1px solid rgb(146 155 152 / 50%) !important;
    border-radius: 8px;
    height: 80px;
    width: 180px;
}

.explorercard-wrapper {
    height: 100%;
    padding: 20px;
    /* background-color: #312F69; */
    border-radius: 12px;
    /* border: 2px solid #312F69; */
    /* background-image: url("./assets/explorerbg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 195px;
    /* width: 27%; */
    transition: all .25s;
}

.explorercard-wrapper:focus,
.explorercard-wrapper:hover,
.explorercard-wrapper:active {
    border: 1px solid white;
}

.purplediv {
    width: 4px;
    height: 40px;
    position: absolute;
    border-radius: 0px 100px 100px 0px;
    left: -1px;
    background: #554fd8;
}

.explorercard-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: white;
}

.topapr-title {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    text-align: right;
    letter-spacing: -0.018em;
    color: #f7f7fc;
}

.topapr-amount {
    font-weight: 600;
    font-size: 32px;
    text-align: right;
    letter-spacing: -0.018em;
    color: #f7f7fc;
}

.explorercard-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 17px;
    color: rgb(153, 153, 153);
    /* width: 43%; */
}

.explorercard-btntext {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #4ED5D2;
}

.explorer-chart {
    width: 200px;
}

@media screen and (max-width: 786px) {
    .explorer-chart {
        position: absolute;
        right: 0;
        top: 5px;
        width: 150px;
    }
}

.govcard-wrapper {
    padding: 18px;
    border-radius: 12px;
    /* background-image: url("./assets/govbg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 50%;
    transition: all .25s;
    cursor: pointer;
}

.govcard-wrapper:focus,
.govcard-wrapper:hover,
.govcard-wrapper:active {
    border: 2px solid rgb(142, 151, 205);
}

.greendiv {
    width: 4px;
    height: 45px;
    position: absolute;
    border-radius: 0px 100px 100px 0px;
    left: -1px;
    background: #8890C4;
}

.govcard-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #f7f7fc;
}

.governancecard-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: rgb(153, 153, 153);
    /* width: 80%; */
}

.govcard-btntext {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #7770e0;
}

@media screen and (max-width: 786px) {
    .govcard-wrapper {
        width: 100%;
    }
}

.bridgecard-wrapper {
    padding: 18px;
    border-radius: 12px;
    /* background-image: url("./assets/bridgebg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 50%;
    transition: all .25s;
    cursor: pointer;
}

.bridgecard-wrapper:focus,
.bridgecard-wrapper:hover,
.bridgecard-wrapper:active {
    border: 2px solid rgb(142, 151, 205);
}

.orangediv {
    width: 4px;
    height: 45px;
    position: absolute;
    border-radius: 0px 100px 100px 0px;
    left: -1px;
    background: #8890C4;
}

.bridgecard-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #f7f7fc;
}

.bridgecard-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: rgb(153, 153, 153);
    /* width: 80%; */
}

.bridgecard-btntext {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #7770e0;
}

@media screen and (max-width: 786px) {
    .bridgecard-wrapper {
        width: 100%;
    }
}