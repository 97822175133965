.wallet-title {
    color: white !important;
}

.header-greeting {
    color: white !important;
    font-weight: 600 !important;
}

.headerdesc {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: -0.018em;
    /* opacity: 0.4; */
    /* color: rgb(153, 153, 153) !important; */
}

.buydyp-btn {
    border-radius: 8px;
    width: fit-content;
    padding: 10px;
    font-size: 1rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #2a2859;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)),
        linear-gradient(90.74deg, #70b8e0 0%, #4f75d8 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #554fd800 inset;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 34px;
}

.buy-dyp-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #2A2859 !important;
}

.change-chain-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #F7F7FC !important;
}

.dropdown-toggle {
    background: #3A377A;
    border-radius: 8px;
    height: 34px;
    padding: 8px;
    /* width: 84px; */
    height: 34px;
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-menu.show {
    background: #26264f !important;
    box-shadow: 0px 12px 16px rgba(20, 20, 41, 0.4) !important;
    border-radius: 8px !important;
    padding: 0px;
    /* min-width: 100%; */
}

.dropdown-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #f7f7fc !important;
    padding: 10px 8px;
}

.dropdown-item:nth-child(1) {
    border-bottom: 1px solid #3b3c68 !important;
}

.dropdown-item:nth-child(2) {
    border-bottom: 1px solid #3b3c68 !important;
}

.dropdown-item:hover {
    background: #3b3883;
}

.dropdown-title {
    display: flex;
    gap: 5px;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #8e97cd;
}

#dropdown-basic-button2:hover {
    text-decoration: none !important;
}

#dropdown-basic-button4 {
    background: #2a2859 !important;
    border-radius: 50%;
    border: none !important;
    /* border-color: #57b6ab !important; */
    width: 56px !important;
    height: 56px !important;
}


.connecttitle {
    color: #75cac2 !important;
}

.walletaccount {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #75cac2 !important;
}

.account-info {
    background: #2a2859;
    border: 1px solid #4ed5d2;
    border-radius: 8px;
    padding: 2px;
}

.account-balance {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #fcfcf7;
    padding: 6px;
}

.account-address {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #2a2859 !important;
    padding: 6px;
    border-radius: 6px;
    background: linear-gradient(273.23deg, #1EC2BE 0.45%, #85ECE9 100%);
}

.hide-it {
    display: none !important;
}

.blocks {
    display: block !important;
}

#dropdown-basic-button2, #dropdown-basic-button2:hover {
    background: transparent !important;
    border: 1px solid white;
    border-radius: 8px;
    width: fit-content;
}