.referral-earnings-caption {
    min-height: 1.5rem;
    font-size: 0.9rem !important;
    /*line-height: 1.6rem !important;*/
    color: white !important;
}

.top-component {
    -webkit-box-pack: center;
    justify-content: center;
}

.flex-cont {
    display: flex;
}

.friend-block {
    position: relative;
    padding: 2.3rem 2rem 0.75rem;
    flex-direction: column;
    color: rgb(255, 255, 255);
    border-radius: 1.56rem;
    width: 100%;
    /*border: 1px solid rgb(146 155 152 / 50%);*/
    background: rgb(146 155 152 / 45%);
}

.top-head {
    min-height: 1.1rem;
    font-size: 1.5rem;
    line-height: 1.2rem;
    font-weight: bold;
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-style: normal;
    font-family: 'Plus Jakarta Sans', sans-serif;

}

.friend-desc {
    margin: 0.6rem 0px 1.6rem;
    min-height: 1.5rem;
    font-size: 0.9rem !important;
    line-height: 1.6rem !important;
    color: white !important;
    /*opacity: 0.6;*/
}

.colored-text {
    color: rgb(17, 22, 81);
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
    font-family: "Plus Jakarta Sans";
}

.ref-block {
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
}

.container-ref {
    padding-left: 16px;
    padding-right: 16px;
    margin-left: auto;
    margin-right: auto;
}

.left-blk {
    position: relative;
    padding: 1.7rem 2rem 20px;
    flex-direction: column;
    color: rgb(255, 255, 255);
    border-radius: 1.7rem;
    border: 1px solid rgb(146 155 152 / 50%);
    width: 48.15%;
    min-height: 11.6rem;
    margin-top: 2.1rem;
}


.white-bg {
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;
}

.start-flx {
    display: flex;
    -webkit-box-pack: start;
    justify-content: start;
}

.full-width {
    width: 100%;
}

.active-ref {
    color: white !important;
    font-weight: 600 !important;
    font-style: normal;
    font-family: "Plus Jakarta Sans";
    line-height: 1.3rem;
    font-size: 1rem;
}

.oreo-head {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-family: "Plus Jakarta Sans";
    margin-top: 0.7rem;
    margin-bottom: 1.25rem;
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-weight: bold;
}

.withdraw-btn:disabled {
    background-color: #212121 !important;

    color: white;
}

.withdraw-btn:disabled, .withdraw-btn.pancake-button--disabled {
    background-color: #212121 !important;

    cursor: not-allowed;
}

.withdraw-btn {
    -webkit-box-align: center;
    align-items: center;
    border: 0px;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    padding: 0px 24px;
    background: rgba(25, 105, 255, 0.1);
    border-radius: 10px;
    color: rgb(25, 105, 255);
    box-shadow: none;
    height: 2.8rem;
    width: 9rem;
    font-weight: 700;
}

.adjust-block {
    position: absolute;
    width: 6.1rem;
    height: 6.1rem;
    top: 6.3rem;
    right: 1.5rem;
}

.link-block {
    display: flex;
    position: relative;
    padding: 1.7rem 2rem 0px;
    flex-direction: column;
    color: rgb(255, 255, 255);
    border-radius: 1.7rem;
    border: none;
    width: 48.15%;
    height: 11.6rem;
    background: #212121 !important;
    margin-top: 2.1rem;
}

.link-head {
    color: white !important;
    font-weight: 600 !important;
    font-style: normal;
    font-family: "Plus Jakarta Sans";
    margin-top: 0.7rem;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.9rem;
}

.relative-p {
    position: relative;
}

.input-block {
    width: 100%;
    height: 2.8rem;
    padding-left: 1.25rem;
    border-radius: 0.6rem;
    background: rgba(255, 255, 255, 0.2);
    -webkit-box-pack: start;
    justify-content: start;
    -webkit-box-align: center;
    align-items: center;
}



.white-link {
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
    font-family: "Plus Jakarta Sans";
    line-height: 1.9rem;
    color: rgb(255, 255, 255);
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.copy-link-btn {
    position: absolute;
    top: 0px;
    right: 0px;
}

.inner-copy:disabled, .inner-copy.pancake-button--disabled {
    background-color: #212121 !important;

    /* cursor: not-allowed; */
}

.absolute-btn {
    background: rgba(255, 255, 255, 0.4);
    width: 45.92px;
    height: 45px !important;
}

.inner-copy {
    -webkit-box-align: center;
    align-items: center;
    border: 0px;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    height: 56px;
    padding: 0px 24px;
    color: white;
    background: rgb(54, 133, 237);
    box-shadow: rgb(5 89 155 / 24%) 0px 4px 10px;
    border-radius: 10px;
    font-weight: 700;
}

.list-ref {
    margin: 2rem 0rem;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.inner-list {
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(5 89 155 / 15%) 10px 10px 30px;
    border-radius: 1.6rem;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 1.8rem 2rem 2.2rem;
    width: 100%;
}

.list-head {
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
}

.active-list {
    color: white !important;
    font-weight: 600 !important;
    font-style: normal;
    font-family: "Plus Jakarta Sans";
    font-size: 1.6rem;
    line-height: 1.43rem;
}

.referral-gray {
    color: rgb(165, 168, 190);
    font-style: normal;
    font-family: "Plus Jakarta Sans";
    margin-top: 0.5rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.3rem;
}

.wallet-blk {
    width: 14.6rem;
    position: relative;
}

.wallet-inputfield {
    width: 100%;
    height: 2.8rem;
    padding: 0.75rem 3.5rem 0.75rem 0.8rem;
    color: rgb(17, 22, 81);
    font-size: 1rem;
    line-height: 1.3rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(165, 168, 190, 0.5);
    border-radius: 10px;
}

.absolute-bn {
    position: absolute;
    top: 0px;
    right: 0px;
}

.search-btn {
    background: rgb(56, 137, 235);
    width: 2.8rem;
    height: 2.8rem;
    -webkit-box-align: center;
    align-items: center;
    border: 0px;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    height: 56px;
    padding: 0px 24px;
    color: white;
    background: rgb(54, 133, 237);
    box-shadow: rgb(5 89 155 / 24%) 0px 4px 10px;
    border-radius: 10px;
    font-weight: 700;
}

.unlock-wallet {
    min-width: 9rem;
    width: 30%;
    padding: 0px;
    margin: 1.25rem 0px 0px;

    -webkit-box-align: center;
    align-items: center;
    border: 0px;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    height: 56px;
    padding: 0px 24px;
    color: white;
    background: #212121 !important;
    border-radius: 10px;
    font-weight: 700;
}

.block-last {
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;
    border: 1px solid rgb(146 155 152 / 50%);
    border-radius: 1.6rem;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 1.8rem 2rem 2.2rem;
    width: 100%;

}

.search-btn {
    -webkit-box-align: center;
    align-items: center;
    border: 0px;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    height: 56px;
    padding: 0px 24px;
    color: white;
    background: rgb(54, 133, 237);
    box-shadow: rgb(5 89 155 / 24%) 0px 4px 10px;
    border-radius: 10px;
    font-weight: 700;
    background-color: #212121 !important;

    width: 2.8rem;
    height: 2.8rem;
}

.search-svg {
    align-self: center;
    fill: rgb(255, 255, 255);
    flex-shrink: 0;
}

@media screen and (min-width: 968px) {
    .inner-ref {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

@media screen and (min-width: 576px) {
    .inner-ref {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

.inner-ref {
    min-height: calc(100vh - 79px);
    padding-top: 16px;
    padding-bottom: 16px;
}

@media screen and (min-width: 370px) {
    .container-ref {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media (max-width: 62rem) {
    .ref-block {
        flex-wrap: wrap;
    }

    .left-blk, .link-block {
        width: 100%;
    }
}

@media (max-width: 48rem) {
    .wallet-blk {
        width: 100%;
        margin-top: 1rem;
    }
}

.main-referral {
    min-height: calc(100vh - 79px);
}