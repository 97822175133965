.form-container {
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
    border-radius: 12px;
}

.progress-container {
    height: 331px;
}

.progress-table {
    width: 48%;
}

.progress-circle {
    width: 48%;
}

.footer-pad {

    width: 100%;
    padding-right: calc((3rem) * .5) !important;
    padding-left: calc((3rem) * .5) !important;
    margin-right: auto;
    margin-left: auto;
}

.form-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.018em;
    color: #857dfa !important;
}

.form-sub-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #f7f7fc !important;
}

.upload-container {

    border-radius: 8px;
    height: 110px;
    width: 110px;
}

.error-upload-container {
    border: 1px dashed rgba(248, 132, 91, 1);
}

.form-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 38px;
}

.additional-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}

.funding-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}

.launchpad-dropdown {
    font-size: 12px;

}

.image-tip {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #c0c9ff !important;
}

.form-divider {
    border-radius: 50px;
    height: 1px;
    color: #0e4343 !important;
    width: 100% !important;
    opacity: 1 !important;
}

.required-star {
    color: rgba(248, 132, 91, 1) !important;
}

.error-text {
    position: absolute;
    color: rgba(248, 132, 91, 1) !important;
    font-size: 12px;
    left: 0;
    bottom: -15px;
}

.error-border {
    border: 1px solid rgba(248, 132, 91, 1) !important;
}

.input-icon {
    position: absolute;
    top: 7px;
    left: 7px;
    width: 20px;
    height: 20px;
}

#file-upload {
    opacity: 0;
    position: absolute;
    height: 110px;
    width: 110px;
    cursor: pointer;
}

.clear-fields {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #857dfa !important;
}

.launchpad-item {
    cursor: pointer;

    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 18px !important;
}

.launchpad-item:hover {
    color: #f7f7fc !important;
}

@media screen and (max-width: 786px) {
    .form-grid {
        grid-template-columns: auto;
    }

    .additional-grid {
        grid-template-columns: auto;
    }

    .submit-form-title {
        width: 100% !important;
    }

    .funding-grid {
        grid-template-columns: auto;
    }

    .progress-container {
        height: 100% !important;
    }

    .progress-table {
        width: 100%;
    }

    .progress-circle {
        width: 100%;
    }
}

@media screen and (max-width: 990px) {
    .discx {
        padding: 0px !important;
    }

    .nu-pad {
        padding-bottom: 95px !important;
    }

    .no-mar {
        margin-left: 0 !important;
    }

    .social-and-links {
        align-items: flex-start !important;
    }
}