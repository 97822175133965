.modalHeader button{
    background: none;
}

.modal-header button span {
    font-size: 40px !important;
}  

#contained-modal-title-vcenter {
    color: white
}