.referraldesc {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -.018em;
}

.referraltitle {
    color: white !important;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
}

.styles-module_arrow__K0L3T {
    position: absolute;
    background: inherit;
    width: 8px;
    height: 8px;
    border-right: 1px solid rgb(146 155 152 / 45%) !important;
    border-bottom: 1px solid rgb(146 155 152 / 45%) !important;
    transform: rotate(45deg);
}

.styles-module_dark__xNqje {
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121  !important;
    box-shadow: 0px 10px 13px rgb(0 0 0 / 20%) !important;
    border-radius: 8px !important;
    border: 1px solid rgb(146 155 152 / 50%) !important;

    font-family: 'Poppins', sans-serif !important;
    white-space: pre-line !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    max-width: 300px !important;

    z-index: 9999;
    color: #f7f7fc !important;
}

.copybtn, .copybtn:hover, .copybtn:focus {
    border: 1px solid white;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    width: 73px;
    height: 28px;
    color: white;
}

.referralwrapper {
    border-radius: 8px;
    padding: 10px;
    background: rgb(20 20 20) !important;
    border: 1px solid white;
    width: 100% !important;
}

.locktime-amount {
    font-weight: 600;
    font-size: 20px;
}

.connectbtn {
    width: 130px !important;
    height: 30px !important;
    font-size: 10px;
}

@media (min-width: 991px) {
    .connectbtn {
        width: 100px !important;
        height: 50px !important;
        font-size: 12px;
    }
}

@media (min-width: 1300px) {
    .connectbtn {
        width: 150px !important;
        height: 40px !important;
        font-size: 14px;
    }
}

.mybalance-text {
    font-size: 11px;
    color: white;
}

.withdraw-littletxt {
    font-size: 11px;
    color: white;
}

@media (min-width: 991px) {
    .pool-deposit-stake {
        position: relative;
        right: 20px;
    }
}

@media (min-width: 991px) {
    .pool-deposit-rewards {
        position: relative;
        right: 10px;
    }
}

.start-title {
    color: white;
}

.bottomwrapper {
    background: linear-gradient(0deg, #14142a, rgba(20, 20, 42, 0));
}

.pool-details-header {
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;
    border: 1px solid rgb(146 155 152 / 50%);
    border-radius: 8px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 40px;
}

.poolscardwrapper {
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;
    border: 1px solid rgb(146 155 152 / 50%);
}

.rotate-it {
    transform: rotate(180deg);
    color: white !important;
}

.details-wrapper {
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121  !important;
}

.allwrappercaws {
    /*background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;*/
    border: 2px solid rgb(146 155 152 / 45%);
}

.allwrapper {
    border-radius: 12px;
    border: 1px solid rgb(146 155 152 / 45%);
}

.dbay, .dbay:hover, .dbay:focus {
    color: white !important;
    margin-top: -5px;
    border: 1px solid white;
}

.spw {
    gap: 0.5rem !important;
}

.purplediv {
    background: white !important;
}


.overlay-tip {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 1000;
    left: 0;
}

.clr {
    color: white;
}

/* <style data-jss="" data-meta="MuiTooltip"> */
.MuiTooltip-popper {
    z-index: 1500;
    pointer-events: none;
}

.MuiTooltip-popperInteractive {
    pointer-events: auto;
}

.MuiTooltip-popperArrow[x-placement*="bottom"] .MuiTooltip-arrow {
    top: 0;
    left: 0;
    margin-top: -0.71em;
    margin-left: 4px;
    margin-right: 4px;
}

.MuiTooltip-popperArrow[x-placement*="top"] .MuiTooltip-arrow {
    left: 0;
    bottom: 0;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: -0.71em;
}

.MuiTooltip-popperArrow[x-placement*="right"] .MuiTooltip-arrow {
    left: 0;
    width: 0.71em;
    height: 1em;
    margin-top: 4px;
    margin-left: -0.71em;
    margin-bottom: 4px;
}

.MuiTooltip-popperArrow[x-placement*="left"] .MuiTooltip-arrow {
    right: 0;
    width: 0.71em;
    height: 1em;
    margin-top: 4px;
    margin-right: -0.71em;
    margin-bottom: 4px;
}

.MuiTooltip-popperArrow[x-placement*="left"] .MuiTooltip-arrow::before {
    transform-origin: 0 0;
}

.MuiTooltip-popperArrow[x-placement*="right"] .MuiTooltip-arrow::before {
    transform-origin: 100% 100%;
}

.MuiTooltip-popperArrow[x-placement*="top"] .MuiTooltip-arrow::before {
    transform-origin: 100% 0;
}

.MuiTooltip-popperArrow[x-placement*="bottom"] .MuiTooltip-arrow::before {
    transform-origin: 0 100%;
}

.MuiTooltip-tooltip {
    color: #fff;
    padding: 4px 8px;
    font-size: 0.625rem;
    max-width: 300px;
    word-wrap: break-word;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.4em;
    border-radius: 4px;
    background-color: rgba(97, 97, 97, 0.9);
}

.MuiTooltip-tooltipArrow {
    margin: 0;
    position: relative;
}

.MuiTooltip-arrow {
    color: rgba(97, 97, 97, 0.9);
    width: 1em;
    height: 0.71em;
    overflow: hidden;
    position: absolute;
    box-sizing: border-box;
}

.MuiTooltip-arrow::before {
    width: 100%;
    height: 100%;
    margin: auto;
    content: "";
    display: block;
    transform: rotate(45deg);
    background-color: currentColor;
}

.MuiTooltip-touch {
    padding: 8px 16px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.14286em;
}

.MuiTooltip-tooltipPlacementLeft {
    margin: 0 24px;
    transform-origin: right center;
}

@media (min-width:600px) {
    .MuiTooltip-tooltipPlacementLeft {
        margin: 0 14px;
    }
}

.MuiTooltip-tooltipPlacementRight {
    margin: 0 24px;
    transform-origin: left center;
}

@media (min-width:600px) {
    .MuiTooltip-tooltipPlacementRight {
        margin: 0 14px;
    }
}

.MuiTooltip-tooltipPlacementTop {
    margin: 24px 0;
    transform-origin: center bottom;
}

@media (min-width:600px) {
    .MuiTooltip-tooltipPlacementTop {
        margin: 14px 0;
    }
}

.MuiTooltip-tooltipPlacementBottom {
    margin: 24px 0;
    transform-origin: center top;
}

@media (min-width:600px) {
    .MuiTooltip-tooltipPlacementBottom {
        margin: 14px 0;
    }
}

.poolscardwrapper {
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    border-radius: 12px;
    position: relative;
    padding: 10px;
    /* width: 400px; */
    transition: all .25s;
}


.poolscardwrapperexpired {
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
    border-radius: 12px;
    position: relative;
    padding: 10px;
    /* width: 400px; */
    border: 2px solid transparent;
    transition: all .25s;
}



.pools-card-hover:hover {
    border: 2px solid rgb(142, 151, 205);
}

.pools-card-open {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 2px solid #7770df;
}

.dashboard-pool-tag {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -32px;
    width: 110px;
}

.cawstoppick {
    position: absolute;
    top: -8px;
    left: 44%;
}

.purplediv {
    width: 4px;
    height: 40px;
    position: absolute;
    border-radius: 0px 100px 100px 0px;
    left: -1px;
}

.tvl-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #7a81b4 !important;
}

.tvl-amount {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.018em;
    color: #f7f7fc;
}

.apr-amount {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: right;
    letter-spacing: -0.018em;
    color: #f7f7fc;
}

.apr-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: right;
    letter-spacing: -0.018em;
    color: #f7f7fc;
}

.token-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.018em;
    color: #f7f7fc !important;
}

.details-wrapper {
    border-radius: 5px 5px 0px 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    position: absolute;
    bottom: 9px;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    padding-top: 3px;
}

.details-wrapperexpired {
    background: #565891;
    border-radius: 5px 5px 0px 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    position: absolute;
    bottom: 9px;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    padding-top: 3px;
}

.toppick {
    /* border: 2px solid #f0603a; */
    position: absolute;
    right: 55px;
    top: -16px;
}


.staked {
    position: absolute;
    right: 130px;
    top: -16px;
}

.new-pool {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 54px;
}

/* .top-pick::after {
  content: "";
  position: absolute;
  width: 91px;
  height: 33px;
  top: -18px;
  right: 8px;
  background-image: url(./assets/toppick.svg);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 6px 0px;
} */

.details-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.bottomwrapper {
    filter: drop-shadow(0px 32px 64px rgba(17, 17, 17, 0.08));
    border-radius: 8px;
    padding: 10px;
}

.bottomwrapperExpired {
    border-radius: 8px;
    padding: 10px;
}

.pools-details-wrapper {
    border: 1px solid rgb(146 155 152 / 50%);
    border-radius: 12px;
    border: 1px solid #7770df;
    padding: 0 12px 12px 12px;
    gap: 5px;
    justify-content: center;
}

.leftside {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.leftside2 {
    padding: 12px;
}

.otherside {
    background: transparent;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width: 25%;
    justify-content: space-between;
}

.otherside-border {
    background: #212121;
    /* border: 1px solid #3b3c68; */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    justify-content: space-between;
}

/* .activewrapper {
  background: #26264f;
  border-radius: 500px;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  width: fit-content;
} */

.activetxt {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: -0.018em;
    color: white;
    text-shadow: 0px 0px 2px rgba(117, 202, 194, 0.6);
}

.earnrewards-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.018em;
}

.earnrewards-container {
    width: 65%;
}

.earnrewards-token {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.018em;
    color: #c0cbf7;
}

.bottomitems {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.018em;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    cursor: pointer;
}

.bottomitems:hover {
    text-decoration: underline;
}

.title-apr-wrapper {
    padding: 0 10px;
}

.green-btn {
    background: linear-gradient(272.84deg,
            rgba(87, 182, 171, 0.2) 0%,
            rgba(117, 202, 194, 0.2) 100%);
    border-radius: 8px;
    border: 1px solid #75cac2;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #75cac2;
}

.green-btn:hover,
.green-btn:focus {
    background: #75cac2;
    color: #fff;
}

.deposit-txt {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.018em;
    text-transform: uppercase;
    color: #f7f7fc;
}

.styledinput {
    background: #312f69 !important;
    border: 1px solid #8e97cd !important;
    border-radius: 8px !important;
    padding: 20px;
    height: 38px;
    /* width: 120px; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #f7f7fc !important;
}

.amount-txt {
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #8e97cd;
    position: absolute;
    background: #312f69;
    padding: 0 3px;
    top: -8px;
    left: 15px;
}

.maxbtn {
    background: #25254c;
    border: 1px solid #7770e0;
    border-radius: 6px;
    padding: 5px 15px;
    width: 80px;
    height: 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #7770e0;
}

.maxbtn:hover,
.maxbtn:focus {
    background: #7770e0;
    color: #fff;
}

.filledbtn {
    background: #34c38f !important;
    background-size: 300%;
    background-position: 0 0;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    width: 95px;
    height: 35px;
    font-size: 12px;
    line-height: 18px;
    color: black;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
}

.withdrawbtn {
    align-self: center;
    background: #ee7a0e !important;
}

.filledbtn:disabled {
    border: none;
    color: #4a5068;
}

.withdraw-txt {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.018em;
    text-transform: uppercase;
    color: #f7f7fc;
}

.filledbtn:hover, .filledbtn:focus {
    color: white !important;
    background-position: 100% 0 !important;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.09) !important;
}

.pool-coins {
    height: 30px;
    width: 30px;
    transition: all .25s;
}

.pool-coins:hover {
    z-index: 7;
    scale: 1.1;
}



.pool-coins:first-child {
    transform: translateX(0px);
    /* z-index: 6; */
}

.pool-coins:nth-child(2) {
    transform: translateX(-10px);
    /* z-index: 5; */
}

.pool-coins:nth-child(3) {
    transform: translateX(-20px);
    /* z-index: 4; */
}

.pool-coins:nth-child(4) {
    transform: translateX(-30px);
    /* z-index: 3; */
}

.pool-coins:nth-child(5) {
    transform: translateX(-40px);
    /* z-index: 2; */
}

.pool-coins:nth-child(6) {
    transform: translateX(-50px);
    /* z-index: 2; */
}

.pool-coins:nth-child(7) {
    transform: translateX(-60px);
    /* z-index: 2; */
}

.pool-coins:nth-child(8) {
    transform: translateX(-70px);
    /* z-index: 2; */
}

.pool-coins:nth-child(9) {
    transform: translateX(-80px);
    /* z-index: 2; */
}

.pool-coins:nth-child(10) {
    transform: translateX(-90px);
    /* z-index: 2; */
}

.pool-coins:nth-child(11) {
    transform: translateX(-100px);
    /* z-index: 2; */
}

@media screen and (max-width: 786px) {

    .poolscardwrapper {
        padding: 6px;
    }

    .details-wrapper {
        bottom: 6px;
    }

    /* 
  .poolscardwrapper > .purplediv{
    top: 6px !important;
  } */

    .title-apr-wrapper {
        padding-top: 6px;
    }

    .earnrewards-container {
        width: 100% !important;
    }

    .tvl-amount {
        font-size: 17px;
    }
}

.selected-border {
    border: 1px solid white !important
}