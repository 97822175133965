.testbar {
    /* position: fixed; */
    left: 0;
    top: 0;
    height: 100%;
    background: #2A2859;
    /* width: 85px; */
    z-index: 11;
    transition: all .25s ease-in-out;
    /* overflow: hidden; */
    /* background-image: url('../../assets/sidebarBackground.webp'); */
    background-size: cover;
    overflow-y: scroll;
    scrollbar-width: none !important;

}

.testbar::-webkit-scrollbar {
    width: 0 !important;
}


.mobile-sidebar {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: #2A2859;
    height: 70px;
    z-index: 5;
    border-top: 2px solid #565891;
}

.explorer-modal {
    /*background: #2A2859;*/
    border-radius: 12px;
    position: absolute;
    bottom: -500px;
    transition: all .3s;
    width: 90%;
    z-index: -1;
}

.explorer-modal-active {
    bottom: 100px;
    box-shadow: 0 0 0 1000px rgb(0 0 0 / 50%);
}

.mobile-modal-item {
    border-bottom: 2px solid #565891;
}

.mobile-modal-item:last-child {
    border-bottom: none !important;
}

.testbar-open {
    width: 250px;
    height: 100%;
    border-right: 1px solid rgb(146 155 152 / 45%);
}

.sidebar-item {
    cursor: pointer;
    gap: 10px;
    border-radius: 12px;
    transition: all .25s;
}

.indicator {
    transition: all .25s;
}

.active-indicator {
    transform: rotate(180deg);

}

.active-width {
    width: 200px;
}


.sidebar-item:hover {
    border: 2px solid #565891;
    background: #1E1C40;


}

.gap-2 {
    gap: 0.5rem !important;
}


.active-side-link-dropdown {
    background: #1E1C40;
    border: 2px solid #565891;
}

.accordion-child {
    font-family: 'Poppins', sans-serif;
    color: #8E97CD !important;
    border: 1px solid #2A2859;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 500;
    padding: 15px;
    cursor: pointer;
    transition: all .25s;
}

.accordion-child:hover {
    background: #1E1C40;
}

.accordion-child-active {
    background: #1E1C40;
    color: #F7F7FC !important;
    /* border: 1px solid rgba(240, 97, 59, 1); */
    /* width: fit-content; */

}

.sideitem-text {
    color: white !important;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.active-text {
    color: #F7F7FC !important;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.dypius-button {
    padding: 7px 15px;
    background: #14142C;
    border-radius: 8px;
    border: 2px solid rgba(60, 62, 108, 1);
}

.sidebar-container {
    gap: 1.5rem !important;
    /* margin-left: -0.375rem; */
}

.sideitem-text {
    margin: 0 !important;
}

.mobile-sidebar {
    background: rgb(3, 3, 3) !important;
    border-top: 1px solid rgb(146 155 152 / 45%);
}

.sidebar-item:hover {
    border: 1px solid white;
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;
}

.active .active-side-link, .active-side-link:hover {
    border: 1px solid white !important;
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;
}

.active .active-side-icon {
    border: 1px solid white;
}

.active-text {
    color: white !important;
}

.hide-it {
    display: none;
    width: 0px !important;
}

a {
    text-decoration: none !important;
}

/* collapse button */
.collapse-div{
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    justify-content: center;
    -webkit-box-pack: center;
    align-items: center;
    -webkit-box-align: center;
    display: flex;
    cursor: pointer;
}

.collapse-div-svg {
    transition: transform 0.1222s ease 0s;
    pointer-events: none;
    width: 14px;
    height: 14px;
    fill: currentcolor;
    color: rgb(255, 255, 255);
}

.collapse-div:hover svg {
    scale: 1.333;
}

.hover-scale{
 cursor: pointer;
}

.hover-scale:hover svg {
    scale: 1.333;
}