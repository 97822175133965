.faq-paragraph {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    letter-spacing: -0.018em !important;
    color: #f0f0f5 !important;
    padding: 10px;
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;
    border-top: none;
}

.no-border {
    border-bottom: none !important;
}

.faq-separator {
    padding-top: 20px !important;
}

.faqwrapper h2 {
    margin: 0 !important;
}