/* Base CSS */
/* @font-face {
    font-family: "Calibre Bold";
    src: url(../fonts/Calibre-Bold.ttf);
}

@font-face {
    font-family: "Calibre Medium";
    src: url(../fonts/Calibre-Medium.ttf);
}

@font-face {
    font-family: "Calibre Regular";
    src: url(../fonts/Calibre-Regular.ttf);
}

@font-face {
    font-family: "Calibre Light";
    src: url(../fonts/Calibre-Light.ttf);
} */

body.theme-dark {
    background: #000 !important;
}

.newscard-wrapper, .newscard-wrapper:hover {
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121 ;
    border: 1px solid rgb(146 155 152 / 50%);
}

:focus,
button:focus {
    outline: 0;
}



em {
    color: #f7f7fc !important;
}

.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320;
}


.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}


.lockertitle-text {
    font-size: 18px;
}




img {
    max-width: 100%;
    height: auto;
}







/*Proloader START CSS*/

.proloader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    background: #fff;
}

.loader_34 {
    /* Preloader */
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.loader_34 .ytp-spinner {
    /* Spinner Container */
    position: absolute;
    left: 50%;
    top: 50%;
    width: 64px;
    margin-left: -32px;
    z-index: 18;
    pointer-events: none;
}

.loader_34 .ytp-spinner .ytp-spinner-container {
    pointer-events: none;
    position: absolute;
    width: 100%;
    padding-bottom: 100%;
    top: 50%;
    left: 50%;
    margin-top: -50%;
    margin-left: -50%;
    animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
    -webkit-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
}

.loader_34 .ytp-spinner-rotator {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.loader_34 .ytp-spinner-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
}

.loader_34 .ytp-spinner-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.loader_34 .ytp-spinner-left {
    /* Position inside Container*/
    right: 50%;
}

.loader_34 .ytp-spinner-right {
    /* Position inside Container*/
    left: 50%;
}

.loader_34 .ytp-spinner-circle {
    box-sizing: border-box;
    position: absolute;
    width: 200%;
    height: 100%;
    border-style: solid;
    border-color: var(--preloader-clr) var(--preloader-clr) transparent;
    /* Spinner Color */
    border-radius: 50%;
    border-width: 6px;
}

.loader_34 .ytp-spinner-left .ytp-spinner-circle {
    left: 0;
    right: -100%;
    border-right-color: transparent;
    -webkit-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.loader_34 .ytp-spinner-right .ytp-spinner-circle {
    left: -100%;
    right: 0;
    border-left-color: transparent;
    -webkit-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

/* Preloader Animations */

@-webkit-keyframes ytp-spinner-linspin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes ytp-spinner-linspin {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes ytp-spinner-easespin {
    12.5% {
        -webkit-transform: rotate(135deg);
    }

    25% {
        -webkit-transform: rotate(270deg);
    }

    37.5% {
        -webkit-transform: rotate(405deg);
    }

    50% {
        -webkit-transform: rotate(540deg);
    }

    62.5% {
        -webkit-transform: rotate(675deg);
    }

    75% {
        -webkit-transform: rotate(810deg);
    }

    87.5% {
        -webkit-transform: rotate(945deg);
    }

    to {
        -webkit-transform: rotate(1080deg);
    }
}

@keyframes ytp-spinner-easespin {
    12.5% {
        transform: rotate(135deg);
    }

    25% {
        transform: rotate(270deg);
    }

    37.5% {
        transform: rotate(405deg);
    }

    50% {
        transform: rotate(540deg);
    }

    62.5% {
        transform: rotate(675deg);
    }

    75% {
        transform: rotate(810deg);
    }

    87.5% {
        transform: rotate(945deg);
    }

    to {
        transform: rotate(1080deg);
    }
}

@-webkit-keyframes ytp-spinner-left-spin {
    0% {
        -webkit-transform: rotate(130deg);
    }

    50% {
        -webkit-transform: rotate(-5deg);
    }

    to {
        -webkit-transform: rotate(130deg);
    }
}

@keyframes ytp-spinner-left-spin {
    0% {
        transform: rotate(130deg);
    }

    50% {
        transform: rotate(-5deg);
    }

    to {
        transform: rotate(130deg);
    }
}

@-webkit-keyframes ytp-right-spin {
    0% {
        -webkit-transform: rotate(-130deg);
    }

    50% {
        -webkit-transform: rotate(5deg);
    }

    to {
        -webkit-transform: rotate(-130deg);
    }
}

@keyframes ytp-right-spin {
    0% {
        transform: rotate(-130deg);
    }

    50% {
        transform: rotate(5deg);
    }

    to {
        transform: rotate(-130deg);
    }
}

/*return-to-top START CSS*/

.back-to-top {
    font-size: 24px;
    width: 45px;
    height: 45px;
    line-height: 42px;
    text-align: center;
    display: none;
    position: fixed;
    bottom: 30px;
    right: 20px;
    z-index: 999;
    border-radius: 50%;
}

.back-to-top i {
    color: #fff;
    line-height: 42px;
}

.btn:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.btn.v1 {
    /* font-size: 20px; */
    color: #fff;
    border: none;
    /* font-family: "Calibre Medium"; */
    /* line-height: 38px; */
    height: 38px;
    padding: 2px 13px 2px;
    border-radius: 3px;
}

.btn.v2,
.btn.v3 {
    color: #fff;
    border: none;
    background: transparent;
    /* height: 38px; */
    border-radius: 3px;
    padding: 1px 15px;
}

.btn.v2 img {
    position: relative;
    top: 2px;
}

.btn.v3 {
    /* font-size: 20px; */
    color: #fff;
    border: none;
    /* background: #988ef9; */
    /* font-family: "Calibre Medium"; */
    /* line-height: 20px; */
    /* padding: 9px 16px 5px; */
    border-radius: 3px;
}

.btn.v3 img {
    position: relative;
    top: -2px;
}

.mt-30 {
    margin-top: 30px;
}

/*--------------------------------
       Header CSS
---------------------------------*/
/* Header bar area Start */

.top-right-header {
    display: flex;
    gap: 10px;
    align-items: center;
    max-width: max-content;
    /* margin: auto; */
    border-radius: 8px;
    min-height: fit-content;
    justify-content: center;
}

.walletwrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: auto;
    gap: 20px;
    align-items: center;
}

.secondbox-wrapper {
    background: #ffffff;
    box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    /* max-width: 60%; */
    width: 100%;
    height: 216px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

/* .MuiCircularProgress-root {
  transform: rotate(-180deg) !important;
  color: #d32f2f !important;
} */

.css-nt50a2-MuiCircularProgress-root {
    color: rgb(153, 153, 153) !important;
}

/* .favorites {
  width: 36%;
} */

.dZkckO::before {
    border-top: 2px solid #fff !important;
    border-right: 2px solid #fff !important;
}

.dZkckO {
    background: #fa4833 !important;
    box-shadow: 0 0 5px 0 #fa4833 !important;
    opacity: 1 !important;
}

.bBfHpH {
    background: #fa4833 !important;
    box-shadow: 0 0 5px 0 #fa4833 !important;
    opacity: 1 !important;
}

.bBfHpH::before {
    border-top: 2px solid #fff !important;
    border-right: 2px solid #fff !important;
}

.backbtn {
    justify-content: center;
    align-items: center;
    display: flex;
    background: linear-gradient(to right, #ed213a, #93291e);
    position: absolute;
    left: 1rem;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    cursor: pointer;
    /* top: 2rem; */
}

.bridged {
    display: flex;
    flex-direction: column;
    top: 7px;
}

.header-wrap {
    position: sticky;
    top: 0;
    right: 0;
    width: 100%;
    /* padding: 30px; */
    padding-top: 30px;
    padding-bottom: 30px;
    z-index: 9;
    /* background: rgb(3, 3, 3) !important; */
    background: rgb(3, 3, 3) !important;
    border-bottom: 1px solid rgb(146 155 152 / 50%);
    /* transition: 0.3s; */
}

/*sticky START CSS*/

.header-wrap.sticky {
    -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
}

.header-wrap {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    /* padding-left: 2rem; */
}

.header-left {
    width: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 10%;
    /* transition: 0.3s; */
}

.header-left div {
    margin-right: 30px;
}

.header-left div:last-child {
    margin-right: 0;
}

.header-left a,
.header-left span {
    font-size: 1.2 rem;
    /* line-height: 24px; */
    text-transform: uppercase;
    /* font-family: "Calibre Medium"; */
}

.header-left .dropdown span,
.header-left div:last-child a img {
    margin-left: 3px;
}

.header-left img {
    position: relative;
    /* top: 3px; */
}

/*  */

.navlinkitem {
    flex-direction: column;
    display: flex !important;
    align-items: center;
    gap: 20px;
    position: absolute;
    width: 100px;
}

.header-right {
    width: 35%;
    padding-right: 25px;
    overflow: hidden;
}

.marquee {
    -webkit-animation: marquee 10s linear infinite running;
    -moz-animation: marquee 10s linear infinite running;
    -o-animation: marquee 10s linear infinite running;
    -ms-animation: marquee 10s linear infinite running;
    animation: marquee 10s linear infinite running;
}

.marquee ul li {
    display: inline-block;
    margin-left: 20px;
}

.marquee ul li a {
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    min-width: 70px;
    display: block;
}

.marquee ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.marquee ul li a {}

.marquee:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    -ms-animation-play-state: paused;
    animation-play-state: paused;
}

@-moz-keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -o-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }
}

@-webkit-keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -o-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }
}

@-o-keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -o-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }
}

@keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -o-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }
}

/*---------------------------------
        Common CSS
--------------------------------*/

.content-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px;
    /* margin-left: 268px; */
}

/*---------------------------------
        Sidebar CSS
--------------------------------*/

.sidebar-link {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    text-align: center;
    letter-spacing: -0.018em;
    color: #c3cad9 !important;
}

.sidebar-link2 {
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 15px !important;
    margin-left: 5px;
}

.account-wrapper {
    background: linear-gradient(100.22deg, #06e386 -14.12%, #5e9af3 107.46%);
    max-width: 275px;
    margin: auto;
    padding: 10px;
    border-radius: 8px;
}

.networks {
    /* display: flex; */
    justify-content: center;
    background: #f7f8fa;
    padding: 3px;
    border-radius: 6px;
    /* gap: 20px; */
}

.networks a {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    padding: 10px;
    border-radius: 6px;
    height: 40px;
}

.networks a img {
    max-width: 100%;
    margin-right: 5px;
    width: 20px;
}

.hoverNetwork:hover {
    background-color: gainsboro;
}

.sidebar {
    background: url(https://app.dypius.com/img/sidebar-hero.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 17%;
    height: 100vh;
    padding: 110px 0 60px;
    overflow-y: auto;
    z-index: 999;
    /* transition: 0.3s; */
}

.twolinks-wrapper {
    background: #ffffff;
    border: 2px solid #edeff2;
    border-radius: 8px 8px 0px 0px;
    justify-content: center;
}

.toggle-button-wrapper {
    min-width: 52px;
    width: 52px;
    height: 26px;
    background: #d9d9d9;
    border-radius: 100px;
    background-position: right;
    background-repeat: no-repeat;
}

.toggle-button-wrapper .toggle-circle {
    background-image: url("https://app.dypius.com/img/sunActive.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f6f6f6;
}

.toggle-button-wrapper .toggle-circle {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--white);
    left: 4px;
    top: 1px;
    transition: 0.3s;
}

.toggle-button-wrapper .toggle-circle .active-toggle {
    background-image: url("https://app.dypius.com/img/sunPassive.svg");
    background-position: left;
    background-repeat: no-repeat;
}

.toggle-button-wrapper svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.active-toggle .toggle-circle {
    left: 26px;
    background-image: url("https://app.dypius.com/img/moonactive.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f6f6f6;
}

.page_wrapper {
    /* transition: 0.3s; */
    position: relative;
    /* background-image: url('../../../src/assets/bodyBg.png'); */
    /* overflow: hidden; */
}

.nav-radius {
    position: fixed;
    top: 110px;
    left: 85px;
    transition: all .25s ease-in-out;
}

.nav-radius-open {
    left: 250px;
}

.page_wrapper.minimize .sidebar {
    width: 8%;
}

.page_wrapper.minimize .activenavlink {
    transform: scale(1.2);
    width: 55px !important;
}

.page_wrapper.minimize .sidebar-link {
    font-size: 0 !important;
}

/* .page_wrapper.minimize .premiumposition {
  height: 55vh !important;
} */

.theme-dark .logo img.logo-white,
.theme-white .logo img.logo-black {
    display: block;
}

.theme-dark .logo img.logo-black,
.theme-white .logo img.logo-white {
    display: none;
}

.logo .home-menu,
.menu-cat-one ul {
    padding: 25px 25px 0;
}

.menu-cat-two ul {
    padding: 0 25px;
}

.logo {
    margin: 0 0 80px;
}

.logo a img {
    display: block;
    margin: 0 auto;
    width: 140px;
    height: 50px;
    /* transition: 0.3s; */
}

.home-menu a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 7px 15px 10px;
    margin: 0 0 80px;
    height: 40px;
}

.home-menu {
    width: fit-content;
    margin: auto;
    justify-content: center;
    gap: 8px;
    display: flex;
    align-items: center;
}

.menu-cat-one ul li,
.menu-cat-two ul li {
    position: relative;
    /* padding-left: 25px; */
    margin: 42px 0;
}

.home-menu a span,
.menu-cat-one ul li a span,
.menu-cat-two ul li a span {
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    display: inline-block;
    line-height: 1;
    /* margin-left: 10px; */
    transition: 0.1s;
}

.home-menu a img,
.home-menu a i {
    /* transform: scale(0.7); */
    position: relative;
    top: -3px;
    font-size: 30px;
    padding-left: auto;
    text-align: center;
    margin-right: 5px;
}

.menu-cat-one ul li a,
.menu-cat-two ul li a {
    display: block;
}

.carousel .slider-wrapper {
    width: 90% !important;
}

.menu-cat-one ul li a img,
.menu-cat-two ul li a img,
.menu-cat-one ul li a i,
.menu-cat-two ul li a i {
    /* transform: scale(0.6); */
    /* position: absolute; */
    top: -8px;
    left: 0;
    /* transition: 0.2s; */
    font-size: 30px;
    color: var(--red);
    width: 30px;
    height: 30px;
}

.navlinks {
    width: 120px;
    border-right: 2px solid #edeff2;
    transition: transform 0.2s;
}

.navlinks:hover {
    transform: scale(1.2);
    background: #ffffff !important;
    box-shadow: 0px 8px 22px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    border: 2px solid #edeff2;
    z-index: 2;
}

.navlinks:hover span {
    color: var(--red) !important;
}

.passive-avatar {
    filter: blur(2px);
    pointer-events: none;
}

.activenavlink {
    transform: scale(1.2);
    background: #ffffff;
    box-shadow: 0px 8px 22px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    border: 2px solid #edeff2;
    z-index: 2;
    width: 120px;
}

@media only screen and (max-width: 1729px) {
    .activenavlink {
        width: 90px !important;
    }

    /* .crown {
    width: 60px !important;
  } */

    /* .premium-wrapper {
    max-width: 184px !important;
  } */

    /* .networks {
    width: 184px;
    padding: 5px !important;
  } */

    .navlinkitem,
    .activelink {
        gap: 10px !important;
    }

    .navlinks {
        width: 90px !important;
    }

    .menu-cat-one ul li,
    .menu-cat-two ul li {
        height: 90px !important;
    }
}

.navlinks:last-child {
    border-right: none;
}

.menu-cat-one h6,
.menu-cat-two h6 {
    font-size: 16px;
    /*text-transform: uppercase;*/
    letter-spacing: 0.5px;
    /* border-bottom: 1px solid #F9656E; */
    line-height: 1.4;
    /* font-family: "Calibre Light"; */
    margin: 0 25px 26px;
}

.menu-cat-two {
    margin: 3rem 0 0;
}

/*Minimize css*/
.minimize-wrap {
    position: fixed;
    top: 60%;
    left: 17%;
    z-index: 999;
    transform: translateY(-50%);
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* transition: 0.3s; */
}

.page_wrapper.minimize .minimize-wrap {
    left: 8%;
}

.page_wrapper.minimize .header-left {
    margin-left: 7%;
}

.minimize_btn {
    cursor: pointer;
    text-align: center;
    padding: 0px 15px 0 6px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.activelink {
    /* background: #FDF5F6; */
    border-radius: 8px;
    padding: 5px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 95px;
    gap: 20px;
}

.minimize_btn span {
    position: relative;
    width: 0;
    height: 0;
    border-right: 7px solid #fff;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
}

.page_wrapper.minimize .logo a img,
.page_wrapper.minimize .logo a i {
    max-width: 100px;
}

.page_wrapper.minimize .minimize_btn span {
    border-right: none;
    border-left: 7px solid #fff;
}

/* .page_wrapper.minimize .home-menu a span, */
.page_wrapper.minimize .menu-cat-one ul li a span,
.page_wrapper.minimize .menu-cat-two ul li a span,
.page_wrapper.minimize .menu-cat-one h6,
.page_wrapper.minimize .menu-cat-two h6 {
    font-size: 0;
    /* display: none; */
}

.page_wrapper.minimize .menu-cat-one h6 a span,
.page_wrapper.minimize .home-menu .sidebar-link2,
.page_wrapper.minimize .home-menu span {
    font-size: 0;
    /* display: none; */
}

.page_wrapper.minimize .connect-text {
    font-size: 11px !important;
}

.page_wrapper.minimize .notconnect-text {
    font-size: 0px !important;
    display: none;
}

.page_wrapper.minimize .top-right-header {
    width: auto;
    max-width: inherit;
    /* margin-left: 10px;
  margin-right: 10px; */
}

.page_wrapper.minimize .account-wrapper {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
}

::placeholder {
    /* color: rgba(0, 0, 0, 0.3); */
    font-size: 12px;
}

.page_wrapper.minimize .home-menu a {
    padding: 7px;
    align-items: center;
    height: auto;
    /* width: 30px;
  height: 30px; */
}

.page_wrapper.minimize .crown {
    position: relative;
}

.page_wrapper.minimize .upper-wrapper {
    flex-direction: column-reverse;
    align-items: center;
}

.page_wrapper.minimize .upgrade-text {
    display: flex;
    justify-content: center;
}

.page_wrapper.minimize .premium-wrapper {
    width: 120px;
}

.premium-title {
    font-weight: 700 !important;
    font-size: 17px !important;
    line-height: 24px;
    letter-spacing: -0.018em;
    color: #b9891b !important;
}

/* .premiumposition {
  height: 65vh
} */

.premium-subtitle {
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 16px;
    letter-spacing: -0.018em;
    color: #939393 !important;
}

.upgrade-text {
    font-weight: 600 !important;
    font-size: 10px !important;
    line-height: 12px;
    letter-spacing: -0.018em;
    color: #e30613 !important;
}

.crown {
    width: 110px;
    position: absolute;
}

.premium-wrapper {
    background: #ffffff;
    box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 236px;
    margin: auto;
    margin-top: 2rem;
}

.page_wrapper.minimize #wallet {
    /* background: white; */
}

.page_wrapper.minimize .networks {
    gap: 5px;
    justify-content: space-evenly;
}

.page_wrapper.minimize .home-menu {
    gap: 5px;
}

.page_wrapper.minimize .networks a {
    padding: 6px !important;
}

.page_wrapper.minimize .home-menu a img {
    top: 0;
    /* padding: 5px; */
}

.page_wrapper.minimize .networks a img {
    margin-right: 0px;
    width: 25px;
}

.page_wrapper.minimize .menu-cat-one h6,
.page_wrapper.minimize .menu-cat-two h6 {
    margin: 0 0 26px;
}

.page_wrapper.minimize .menu-cat-one ul {
    padding: 20px 0 0;
}

.page_wrapper.minimize .menu-cat-two ul {
    padding: 0;
}

.page_wrapper.minimize .menu-cat-one ul li,
.page_wrapper.minimize .menu-cat-two ul li {
    height: 50px !important;
}

.page_wrapper.minimize .navlinks {
    width: 55px !important;
}

.page_wrapper.minimize .home-menu a img,
.page_wrapper.minimize .home-menu a i {
    transform: scale(1.1);
    display: block;
    margin: 0 auto;
}

.page_wrapper.minimize .menu-cat-one ul li a img,
.page_wrapper.minimize .menu-cat-two ul li a img,
.page_wrapper.minimize .menu-cat-one ul li a i,
.page_wrapper.minimize .menu-cat-two ul li a i {
    /* transform: scale(1.1) translateX(-50%); */
    display: block;
    margin: 0 auto;
    left: 38%;
    position: absolute !important;
    width: 24px;
    height: 24px;
    top: -2px;
}

.nicescroll-cursors {
    border-color: #f9656e !important;
    background: #f9656e !important;
    width: 3px !important;
    /*height: 120px!important;*/
    opacity: 0 !important;
}

/*---------------------------------
        Right Content CSS
--------------------------------*/

.right-content {
    width: 100%;
    /* margin-left: 18%; */
    /* transition: 0.3s; */
    /* padding: 140px 0; */
}

.hidescroll {
    overflow: hidden;
    pointer-events: none;
}

.graph-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.graph-left {
    width: 42%;
    border-right: 1px solid rgba(227, 6, 19, 0.22);
    padding-right: 4%;
}

.graph-right {
    /* width: 55%; */
}

.graph-data-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.graph-data-item p {
    margin: 0;
    line-height: 1;
    opacity: 0.7;
    /* font-size: 18px; */
}

.graph-data-item span {
    /* font-size: 18px; */
    /* font-family: "Calibre Medium"; */
    font-weight: 600;
}

.graph-data-item {
    margin: 8px 0;
}

.graph-data a img {
    margin-right: 7px;
}

.graph-data {
    margin: 34px 0;
}

.graph-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.graph-header-left h2 {
    /* font-family: "Calibre Bold"; */
    opacity: 1;
    font-size: 1.6rem;
    line-height: 32px;
}

.graph-header-left p {
    margin: 0;
}

.jxflYm {
    min-height: 47px !important;
}

.content-title {
    border-bottom: 1px solid #d9d9d94d;
    padding: 0 0 20px;
    margin: 0 0 20px;
    display: grid;
    gap: 20px;
}

.content-title-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.content-title-top h2 {
    font-size: 18px;
    line-height: 36px;
    /* font-family: "Calibre Medium"; */
}

.content-title p {
    margin: 0;
    opacity: 0.6;
}

.content-title-top ul li {
    display: inline-block;
    margin: 0 2px;
}

#searchform {
    position: relative;
}

#search-bar {
    width: 100%;
    height: 42px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    line-height: 21px;
    padding: 10px 0 10px 20px;
}

#submit {
    position: absolute;
    top: 10px;
    right: 14px;
    background: transparent;
    border: none;
}

/*-----autosearch----*/

.output {
    position: absolute;
    padding: 40px 0 0;
    width: 100%;
    margin: 10px 0 0;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    box-shadow: 0 0 18px 5px rgba(0, 0, 0, 0.1);
}

.output::before {
    position: absolute;
    top: 20px;
    left: 0;
    content: "Pairs Ordered By Liquidity";
    font-size: 14px;
    opacity: 0.7;
    display: block;
    padding: 0 28px 20px;
}

.search-minititle {
    font-size: 14px;
    opacity: 0.7;
    display: block;
    padding: 0 28px 20px;
}

.prediction-item:first-child {
    border-top: 1px solid rgba(227, 6, 19, 0.2);
    margin: 10px 0 0;
}

.suggest-item {
    padding: 10px 28px;
    border-bottom: 1px solid rgba(227, 6, 19, 0.2);
}

body.theme-white .prediction-item:last-child .suggest-item {
    border-bottom: none;
}

.suggest-item h2 {
    font-size: 27px;
    line-height: 36px;
    /* font-family: "Calibre Medium"; */
}

.suggest-item h2 span.wh_txt {
    /* font-family: "Calibre Regular"; */
    font-weight: 300;
}

.suggest-item h2 span.bar {
    margin: 0 10px;
}

.suggest-item p {
    font-size: 16px;
    opacity: 0.6;
    margin: 0;
    font-weight: lighter;
    /* font-family: "Calibre Light"; */
    line-height: 20px;
}

.chart-wrap {
    margin: 30px 0 0;
}

.press-release-slider-container {
    width: 93%;
}

body.theme-dark img.graph-one,
body.theme-white img.graph-two {
    display: block;
}

body.theme-white img.graph-one,
body.theme-dark img.graph-two {
    display: none;
}

.graph-header-right {
    display: flex;
    align-items: center;
}

.graph-header-right .btn {
    margin-left: 6px;
}

.progress-title {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 5px;
}

.progress-title p {
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: -0.018em;
    color: #71757e !important;
}

.dXdHiN {
    background: #e30613 !important;
    box-shadow: 0px 4px 4px rgba(227, 7, 19, 0.3) !important;
}

.css-ol7wxs-MuiTypography-root {
    color: #303134 !important;
    font-weight: 700 !important;
    font-size: 15px !important;
}

.score-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 5px;
    gap: 10px;
    /* width: 40%; */
}

#tv_chart_container {
    height: 393px !important;
}

.slick-dots {
    bottom: -55px !important;
}

.slick-dots li.slick-active button:before {
    color: #e30613 !important;
}

#tv_chart_container iframe {
    pointer-events: all;
    border-radius: 8px;
}

.CircularProgressbar {
    width: 60% !important;
    border-radius: 50%;
}

.CircularProgressbar .CircularProgressbar-path {
    /* stroke: #E30613 !important; */
    /* filter: drop-shadow(0px 4px 10px rgba(248, 69, 49, 0.6)) !important; */
}

.CircularProgressbar .CircularProgressbar-text {
    font-weight: 400 !important;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.018em;
}

.score-title {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    letter-spacing: -0.018em;
    color: #303134 !important;
}

.progress-title span {
    /* font-size: 24px; */
    /* font-family: "Calibre Medium"; */
    font-weight: 600;
}

.progress {
    border-radius: 20px;
    position: relative;
    height: 10px;
    width: 100%;
}

.progress-done-one,
.progress-done-two,
.progress-done-three,
.progress-done-four,
.progress-done-five {
    background: linear-gradient(90deg, #e30613 2.37%, #f84531 98.62%);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    opacity: 0;
    /* transition: 1s ease 0.3s; */
}

.slick-dots li button:before {
    font-size: 10px !important;
}

@media screen and (max-width: 767px) {
    .fYAEyH {
        grid-template-columns: repeat(10, 95%) !important;
        grid-column-gap: calc(90% + 43px) !important;
    }

    .kosie {
        grid-template-columns: repeat(10, 97%) !important;
    }

    .press-release-slider-container {
        width: 87%;
    }

    .inMPhr {
        grid-gap: 10px !important;
    }

    .press-title {
        max-width: 335px !important;
    }
}

.latestnews-image {
    border-radius: 6px;
    width: 185px;
    height: 125px;
}

@media screen and (max-width: 1137px) {
    .singlepress-inner {
        flex-wrap: wrap !important;
    }

    .dXvgTB {
        grid-column-gap: calc(55% + 2px) !important;
    }

    .graphwrapper {
        padding: 20px !important;
    }

    .press-release-wrapper {
        padding-top: 60px !important;
    }

    /* .premiumposition {
    height: 77vh  !important;
} */

    .itemdataimg {
        width: 55px;
        height: 24px;
    }

    .social-share-parent2 {
        position: absolute !important;
        top: 24px;
        right: 1.5rem;
    }

    .lockliqtext {
        width: 100% !important;
    }

    .latestnews-item {
        flex-direction: column-reverse;
    }

    .backbtn {
        left: 0 !important;
        top: 0 !important;
        position: relative !important;
        margin-right: 10px;
        width: 70px !important;
    }

    .latestnews-image {
        width: 100%;
        height: 175px;
    }

    .left-col-content iframe {
        width: 318px !important;
    }

    .navlinks,
    .activenavlink {
        width: 119px !important;
    }

    .yUUVS {
        grid-column-gap: calc(2% + 1px) !important;
        margin-bottom: 10px !important;
    }

    .main-title-text {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        font-weight: 600;
        font-size: 16px !important;
        line-height: 19px !important;
        letter-spacing: 0.05em;
        text-align: left;
        color: #fff !important;
        cursor: pointer;
        transition: all .25s;

    }

    .news-title {
        font-size: 24px !important;
        margin-bottom: 10px !important;
    }

    .press-title {
        font-size: 14px !important;
        line-height: 25px !important;
        color: #ffffff !important;
        /* max-width: 204px !important; */
    }

    .singlenews-body {
        /* width: 335px !important; */
    }

    /* .singlenews-wrapper {
    max-width: 290px !important;
  } */

    /* .singlenews-side {
    padding-left: 0 !important;
  } */

    .button-wrapper {
        margin-bottom: 10px;
    }

    /* .singlenews-title {
    font-size: 14px;
  } */

    .inMPhr {
        grid-gap: 10px !important;
        grid-template-columns: repeat(2, 78%) !important;
    }

    .fYAEyH {
        grid-column-gap: calc(59% + 0px) !important;
    }

    .press-image {
        width: auto !important;
        height: auto !important;
        border-radius: 6px;
    }

    /* .single-press-wrapper {
    height: 404px !important;
  } */

    .poolexp-wrapper {
        gap: 20px;
    }
}

/*-----table----*/

.table-title h4 {
    font-size: 18px;
    /* text-transform: uppercase; */
    line-height: 20px;
    /* font-family: "Calibre Medium"; */
    letter-spacing: 0.5px;
}

.table-title {
    padding: 21px 17px 16px;
    margin: 10px 0;
}

.jVeafb {
    font-size: 12px !important;
}

.header-right ul li a {
    font-size: 12px;
    line-height: 16px;
}

.dropdown-text {
    font-size: 12px;
}

.table-head,
.table-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 40px 15px;
    margin: 0 0 2px;
}

.premiumbanner {
    background: linear-gradient(92.82deg, #50AF95 -22.06%, #2B2A59 70.49%);
    border-radius: 8px;
    border: 1px solid #565891;
    padding: 20px;
}

.premiumbanner2 {
    background: url(https://app.dypius.com/img/premiumbanner.png) no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 170px;
    height: 100%;
    margin-top: 2rem;
    padding: 55px;
}

.subscribebtn {
    background: #e30613;
    border-radius: 6px;
    width: 117px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 15px;
    color: #ffffff !important;
}

#group_image {
    /* background: #ffffff;
  border: 1px solid #d8d8d8;
  padding: 10px;
  border-radius: 6px; */
    opacity: 0;
    position: absolute;
    height: 56px;
    width: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}

@media only screen and (max-width: 500px) {
    .premiumbanner {
        padding: 20px !important;
    }

    .lock-text-wrapper {
        width: 100% !important;
    }

    .claim-btn {
        left: 67% !important;
    }

    /* .date-wrapper-press {
    height: 30%;
  } */
}

.subscr-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.018em;
    color: #ffffff !important;
}

.subscr-subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.018em;
    color: #ffffff !important;
}

.subscr-note {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: -0.018em;
    color: rgb(153, 153, 153) !important;
    text-align: right;
}

.subscr-price {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: -0.018em;
    color: #ffffff !important;
    text-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
}

.table-head-item p {
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    font-weight: 500;
    text-transform: capitalize;
}

.table-head-item,
.table-data-item {
    width: 14.6%;
}

.table-head-item:nth-child(2),
.table-head-item:nth-child(6),
.table-head-item:nth-child(8),
.table-data-item:nth-child(2),
.table-data-item:nth-child(6),
.table-data-item:nth-child(8) {
    width: 9%;
}

.table-data-item p {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    font-weight: 500;
    text-transform: capitalize;
}

.page-nav {
    padding: 35px 0;
    background: #000;
}

.page-nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.page-nav ul li {
    margin: 0 10px;
}

.page-nav ul li a {
    background-color: transparent;
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 50%;
    line-height: 31px;
}

.page-nav ul li a img {
    max-width: 52px;
    position: relative;
    top: -2px;
}

/*---------------------------------
        Content NEWS CSS
--------------------------------*/

.news-wrapper {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 12px 16px 44px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 26px;
    position: relative;
}

.banner-slider-arrow {
    position: absolute;
    z-index: 1;
    bottom: 4.5rem;
    display: flex;
    justify-content: end;
    width: 100%;
    padding: 1rem 2rem 0;
}

.eth ::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 33px;
    top: -17px;
    right: -2px;
    background-image: url(https://app.dypius.com/img/svg/ethbg.svg);
    background-repeat: no-repeat;
    border-radius: 6px 0px;
    padding: 5px 10px 5px 0;
}

/* .avax::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 33px;
  top: -17px;
  right: -2px;
  background-image: url(../img/svg/avaxbg.svg);
  background-repeat: no-repeat;
  border-radius: 6px 0px;
  padding: 5px 10px 5px 0;
} */

.news-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #F7F7FC !important;

}

.brand-wrapper {
    position: relative;
}

.banner-wrapper {
    margin-top: 0;
    margin-bottom: 20px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: linear-gradient(180deg, #fa4933 0%, #e30713 100%) !important;
    box-shadow: 0px 4px 4px rgba(227, 7, 19, 0.3) !important;
    width: 15px;
    height: 15px;
}

.news-bottom-wrapper {
    display: flex;
    gap: 27px;
    align-items: center;
    /* position: absolute; */
    /* z-index: 2; */
}

.main-date-item {
    position: absolute;
    left: 2%;
    bottom: 3%;
    z-index: 1;
}

.featured-slider-wrapper {
    background: #312F69;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
    border-radius: 12px;
}

.featured-slider-arrows {
    position: absolute;
    right: 3%;
    bottom: 5%;
}


.news__dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin: auto;
    padding: 0;
    border-radius: 40px;
    width: 70px;
    text-align: center;
    margin-top: 20px;
    position: absolute;
    right: 17%;
    bottom: 4%;
}

.news__dots li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 3px;
    padding: 0;
}

.news__dots li button {
    border: none;
    background: #B3B9DD;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 12px;
    width: 10px;
    opacity: 0.3;
    border-radius: 7.5px;
}

.news__dots li.slick-active button {
    width: 16px;
    height: 16px;
    opacity: 1;
    background-color: #f7f7fc;
    box-shadow: 0 4px 4px 0 rgba(150, 100, 255, 0.24);
}





.like-indicator {
    cursor: pointer;
    width: 32px;
    height: 32px;
}

#dislike {
    /* margin-left: 5px; */
    transform: rotate(180deg);
}

.date-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
}

.date-wrapper-press {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    flex-direction: column;
}

.date-content {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #999999 !important;
    margin: 0;
}

.news-date-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    letter-spacing: -0.018em;
    color: #DBD9FF !important;
}

.press-release-container {
    background: #312F69;
    border-radius: 12px;
}

.press-date-content {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #f5f5f5 !important;
    margin: 0;
}

.press-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.018em;

    color: #F7F7FC !important;

}

.press-image {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
}

.news-image {
    height: 450px;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    object-fit: cover;
}

.tooltip-wrapper {
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-radius: 8px;
    width: 260px;
    justify-content: center;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.15);
    position: absolute;
    /* bottom: 1rem; */
    z-index: 1500;
}

.screens-container {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 40px;
    margin: 15px 0 0;
    /* font-weight: 300 !important; */
    /* font-size: 12px !important; */
    line-height: 15px !important;
    cursor: pointer;
}

.tooltip-text {
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    color: #db2728 !important;
}

.tooltip {
    background: #1A1A36 !important;
    border: 1px solid #3B3C68;
    box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: -70px;
    width: 200px;
}

.MuiTooltip-popper {
    background: #211F45 !important;
    box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px !important;
    border: 1px solid #3B3C68 !important;
}

.MuiTooltip-tooltip,
.MuiTooltip-tooltipPlacementTop {
    background: #211F45 !important;
}

.tooltip-text {
    font-family: 'Poppins', sans-serif !important;
    white-space: pre-line;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    color: #f7f7fc !important;
}


/* 

.tooltip .tooltiptext {
  visibility: hidden;
  font-size: 12px;
  width: 142px;
  background-color: #CCDFD8;
  color: #015E3B;
  text-align: center;
  border-radius: 10px;
  padding: 5px 0;
  position: absolute;
  z-index: 9999 !important;
  top: 100%;
  left: 50%;
  margin-left: -70px;
  opacity: 0;
  font-weight: 600;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #CCDFD8 transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
} */

.tabledata {
    text-align: left;
    padding: 10px;
}

.tablerow {
    border-radius: 8px;
}

.tabledata {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #303134 !important;
}

.tabledata:first-child {
    border-radius: 8px 0 0 8px;
}

.tabledata:last-child {
    border-radius: 0 8px 8px 0px;
    text-align: center;
}

.tabledata:nth-child(even),
.tableheader {
    text-align: center;
}

.premiumtext {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.018em;
    color: #e40b15;
}

.freetext {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.018em;
    color: #303134;
}

.tablerow:nth-child(even) {
    background: #eff0f5;
}

.banner-item {
    /* padding: 10px; */
    background: none;
    border-radius: 12px;
    background-color: transparent !important;
    /* width: fit-content; */
    /* margin: 0 10px; */
}

.top-rated-news {
    border-bottom: 2px solid #565891;
    padding-bottom: 8px;
    border-radius: 0px !important;
}

.top-rated-news:last-child {
    border-bottom: none !important;
    padding-bottom: 0px !important;
}

.rightside-news-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.018em;

    color: #F7F7FC;
}

.slick-next:before {
    color: #fa4833 !important;
    font-size: 32px !important;
    opacity: 1 !important;
}

.slick-next {
    right: -15px !important;
}

.slick-prev:before {
    color: #fa4833 !important;
    font-size: 32px !important;
    opacity: 1 !important;
}

.votes-amount {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.018em;
    color: #FFFFFF !important;
}

/* .like-wrapper {
  display: flex;
  gap: 3px;
  justify-content: center;
} */

.main-title-text {
    /* padding-bottom: 20px; */
    /* padding-top: 20px; */
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.018em;
    color: #F7F7FC !important;
    text-align: left;
    cursor: pointer;
    /* position: absolute; */
    /* top: -2rem; */
    transition: all .25s;
}

.featured-shadow {
    position: absolute;
    pointer-events: none;
    border-radius: 8px;
    transition: all .25s;
    height: 101%;
}

.featured-shadow-hover {
    box-shadow: 100px 123px 200px 0px rgb(0 0 0 / 75%) inset;
}



.main-title-text:hover {
    /* color: #ed2120; */
    text-decoration: underline;
    /* font-weight: 700; */
}



.close-btn {
    display: flex;
    justify-content: end;
    cursor: pointer;
    position: absolute;
    right: 21px;
}

.walletbutton {
    background: var(--box-bg);
    outline: currentcolor none medium;
    border: 1px solid rgb(237, 238, 242);
    border-radius: 12px;
    width: 93% !important;
    display: flex;
    flex-direction: row;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: justify;
    padding: 1rem;
    margin: auto;
    opacity: 1;
}

.graphwrapper {
    padding: 20px 40px;
}

.close-icon {
    position: relative;
    top: 1rem;
}

.details-modal-content {
    padding: 2rem;
    padding-top: 0;
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    /* border-bottom: 1px solid #d5d7e6; */
}

.source-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.018em;
    text-decoration: underline;
    color: #4ED5D2 !important;
}

.news-left-wrapper {
    width: 55%;
}

.main-news-content-wrapper {
    /* justify-content: center; */
}

.othernews-row-wrapper {
    /* justify-content: center; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

@media only screen and (max-width: 770px) {
    .news-left-wrapper {
        max-width: 100%;
        width: 100%;
    }

    .singlenews-side {
        width: 100% !important;
        border-left: none !important;
        overflow: inherit !important;
    }

    .main-news-content-wrapper {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }

    .othernews-row-wrapper {
        justify-content: center;
        grid-template-columns: auto;
    }

    .news-image {
        height: 440px;
    }
}

@media only screen and (max-width: 570px) {
    .news-image {
        height: 370px;
    }
}

@media only screen and (max-width: 470px) {
    .news-image {
        height: 280px;
    }

    .banner-item {
        padding: 0;
        /* margin: 0; */
    }
}

@media (min-width: 360px) {
    .modal-dialog {
        max-width: 1190px;
        margin: 1.75rem auto;
        height: inherit;
        overflow-y: auto;
        /* height: auto; */
        pointer-events: auto;
    }
}

.swap-iframe {
    pointer-events: all !important;
}

.hidescroll {
    overflow: hidden;
    pointer-events: none;
}

.modal-pointer-events {
    pointer-events: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    overflow: hidden;
}

.tags {
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #ffffff;
    background: linear-gradient(86.84deg, #e30613 2.69%, #fc4f36 97.76%);
    border-radius: 4px;
    padding: 10px 20px;
    width: fit-content;
}

.left-col {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
}

/* .fPimMH {
  width: 90% !important;
}

.dluTbn {
  width: 90% !imp[
    ortant;
} */

.walletmodal-wrapper {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modal-main {
    position: fixed;
    background: var(--box-bg);
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px 0px 2rem;
    box-shadow: rgba(47, 128, 237, 0.05) 0px 4px 8px 0px;
    padding: 0px;
    width: 50vw;
    overflow: hidden;
    align-self: center;
    max-width: 420px;
    max-height: 90vh;
    display: flex;
    border-radius: 20px;
}

@media all and (max-width: 576px) {
    .modal-main {
        max-width: 100%;
        width: 90%;
    }

    .ljXtWJ[data-reach-dialog-content] {
        max-width: 100%;
        width: 100%;
    }
}

.latestnews-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.tag-wrapper {
    /* background: linear-gradient(
    to right,
    rgba(35, 37, 38, 0.8),
    rgba(65, 67, 69, 0.5)
  ); */
    /* opacity: 0.8; */
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    height: fit-content;
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: end;
    flex-direction: column;
    padding: 20px;
}

.connectwalletbtn {
    background: #e30613;
    border-radius: 6px;
    width: 67px;
    height: 26px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-right: 10px;
    color: #ffffff;
    border: none !important;
    outline: none !important;
}

.main-image {
    position: relative;
    border-radius: 8px;
}

.singlenews-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    gap: 8px;
    padding-top: 10px;
    border-top: 1px solid #565891;
}

.singlenews-wrapper:first-child {
    border-top: none !important;
}

.othernews-content {
    background: #312F69;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
    transition: all .25s;
    border-radius: 0 0 12px 12px;
    height: 100%;
}


.go-back-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    letter-spacing: -0.018em;

    color: #F7F7FC !important;
}

.go-back-btn {
    background: #565891;
    border-radius: 8px;
}



.singlenews-image {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    object-fit: cover;
}

.singlenews-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FCFCF7 !important;
    cursor: pointer;
}

.singlenews-body {
    /* margin: auto; */
    cursor: pointer;
    /* width: 543px; */
}

.singlenews-side {
    /* height: 520px; */
    /* overflow: auto; */
    /* border-left: 1px solid #d9d9d9; */
    background: #312F69;
    box-shadow: 0px 32px 64px rgb(17 17 17 / 12%);
    border-radius: 12px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    justify-content: space-between;
}

.newmodal {
    /* width: 100%; */
    justify-content: start;
    display: flex;
}


.news-modal {
    background: #312F69;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
    border-radius: 12px;
}

.button-wrapper {
    /* background: #ffffff; */
    border-radius: 6px;
    padding: 5px;
    display: flex;
    /* border: 1px solid #e30613; */
    /* max-width: 420px; */
    /* margin: auto; */
    justify-content: center;
}

.passivebutton {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #8E97CD;
    /* background: white; */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 34px;
    border-top: 2px solid #312F69;
    border-radius: 8px 8px 0 0;
    border-bottom: 2px solid #565891;
}

.passivebutton:hover {
    background: #1E1C40;
    border: 2px solid #565891;
}

.activebutton {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #f7f7fc !important;
    width: 50%;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1E1C40;
    cursor: pointer;
    /* background: linear-gradient(51.32deg, #e30613 -12.3%, #fa4a33 50.14%); */
    border-radius: 8px 8px 0 0;
    border: 2px solid #565891;
}

.press-release-wrapper {
    padding-top: 40px;
    padding-bottom: 20px;
}

.single-press-wrapper {
    /* background: linear-gradient(86.38deg, #e30613 3.49%, #fc4f36 92.45%); */
    /* box-shadow: 12px 16px 44px rgba(0, 0, 0, 0.12); */
    border-radius: 8px;
    /* padding: 16px; */
    cursor: pointer;
}

.press-prev-btn {
    position: absolute;
    left: 1%;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
}

.press-next-btn {
    position: absolute;
    right: 1%;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
}

.other-news-singlewrapper {
    background: #ffffff;
    box-shadow: 12px 16px 44px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    width: 300px;
    height: 100%;
}



.other-news-image {
    /* width: 335px; */
    height: 160px;
    border-top-left-radius: 8px;
    cursor: pointer;
    border-top-right-radius: 8px;
    object-fit: cover;
}

.load-more-btn {
    border-radius: 8px;
    background: rgba(227, 6, 19, 0.1);
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #e30613;
    padding: 10px;
    outline: none !important;
    width: 117px;
    border: none !important;
    margin-top: 20px;
}

.related-news-side-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #F7F7FC !important;
}

.related-subnews-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.018em;

    color: #F7F7FC !important;
}

.slick-slide {
    visibility: hidden;
}

.slick-slide.slick-active {
    visibility: visible;
}

.related-news-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    /* align-items: center; */
    justify-content: center;
}

.single-related-news-wrapper {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}


.left-col-content p {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 30px !important;
    letter-spacing: -0.018em !important;
    color: #F7F7FC !important;

}

.left-col-content a {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 30px !important;
    letter-spacing: -0.018em !important;
    color: #F7F7FC !important;
    background-color: transparent !important;

}

.left-col-content span {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 30px !important;
    letter-spacing: -0.018em !important;
    color: #F7F7FC !important;

}

.left-col-content strong {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 30px !important;
    letter-spacing: -0.018em !important;
    color: #F7F7FC !important;

}

.left-col-content span {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 30px !important;
    letter-spacing: -0.018em !important;
    color: #F7F7FC !important;

}


.right-col {
    /* max-width: 380px; */
    /* border-left: 1px solid #d9d9d9; */
    /* padding-left: 20px; */
    /* width: 70%; */
    background: #312F69;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
    border-radius: 12px;
    padding: 12px;
}

@media only screen and (max-width: 860px) {
    .left-col-title {
        max-width: 100% !important;
        font-size: 1rem !important;
    }

    .newmodal {
        width: 100% !important;
    }

    .left-col-image {
        max-width: 100% !important;
    }

    .right-col {
        display: none;
    }

    .details-modal-content {
        justify-content: center;
        padding: 37px 0;
    }

    .news-wrapper {
        padding: 20px;
    }

    .left-col-content {
        max-width: 100% !important;
        text-align: justify;
    }
}

.modal-bottom-wrapper {
    padding: 32px;
}

/*---------------------------------
        Content Submit info CSS
--------------------------------*/

.required-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    /* opacity: 0.4; */
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.single-cell {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.inputfield {
    border: 1px solid #999999;
    border-radius: 6px;
    width: 69%;
    height: 42px;
    padding: 0 10px;
}

.form-control {
    border: 1px solid #999999;
    border-radius: 6px;
    width: 320px;
}

.form-control ::placeholder {
    /* color: rgba(0, 0, 0, 0.3); */
    font-size: 12px;
}

.submitbtn {
    background: linear-gradient(51.32deg, #e30613 -12.3%, #fa4a33 50.14%);
    border-radius: 6px;
    width: 69%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff !important;
    cursor: pointer;
}

.clearbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e30613;
    border-radius: 6px;
    width: 69%;
    height: 42px;
    cursor: pointer;
}

.clear-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #e30613 !important;
}

.buttons-wrapper {
    display: flex;
    flex-direction: row;
}

.errormessage {
    color: #ff7360 !important;
}

@media only screen and (max-width: 991px) {
    .buttons-wrapper {
        gap: 30px;
    }

    .networks {
        width: auto !important;
    }

    .navlinks,
    .activenavlink {
        width: 119px !important;
    }

    .premium-wrapper {
        max-width: 236px !important;
    }

    .crown {
        width: 110px !important;
    }

    .input-wrapper {
        padding: 10px 0;
    }

    .inputfield,
    .submitbtn,
    .clearbtn {
        width: 100% !important;
    }
}

/*---------------------------------
        Content Account CSS
--------------------------------*/
.accout-left-wrapper {
    background-size: cover;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    border-radius: 12px;
    padding: 20px;
    background-image: url(https://app.dypius.com/img/acount-left-hero.svg);
    background-repeat: no-repeat;
}

.account-left-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff !important;
}

.account-left-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.75px;
    color: #ffffff !important;
}

.account-right-wrapper {
    position: relative;
    border-radius: 12px;
    padding: 20px;
    background-image: url(https://app.dypius.com/img/account-right-hero.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.inputfile-wrapper {
    background: #f6f6f6;
    border-radius: 6px;
    padding: 20px;
    max-width: 520px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
}

.removebtn {
    background: linear-gradient(51.32deg, #e30613 -12.3%, #fa4a33 50.14%);
    border-radius: 6px;
    outline: none !important;
    border: none !important;
    padding: 5px 20px;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #ffffff !important;
    width: 62px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    cursor: pointer;
}

.savebtn {
    background: #fff;
    border-radius: 6px;
    outline: none !important;
    border: 1px solid #e30613;
    padding: 5px 20px;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #e30613 !important;
    width: 62px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    cursor: pointer;
}

.avatarimg {
    height: 56px;
    width: 56px;
    border-radius: 50%;
}

input[type="radio"]:checked {
    accent-color: #e30613;
}

input[type="radio"] {
    cursor: pointer;
}

.subscription-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.4) !important;
}

.radio-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0 10px;
    width: fit-content;
}

.fire {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -2rem;
    right: 0%;
}

/*---------------------------------
        Content Footer CSS
--------------------------------*/
.footer {
    width: 100%;
    background: rgb(3, 3, 3);;
}

body.theme-dark .content-footer {
    background: #2A2859 !important;
}

.content-footer {
    /* margin-top: 30px; */
    padding: 20px 0;
    padding-left: 30px;
    /* width: calc(100% - 250px); */
    margin-right: 0;
    background: #2A2859;
}

.footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.footer-col-container {
    flex: 0 0 auto;
    width: 83.33333333%;
}


.footer-menu {
    border: 0 !important;
}

.footer-menu ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.footer-menu ul li a {
    font-size: 12px;
    line-height: 24px;
    text-transform: capitalize;
    /* font-family: "Calibre Regular"; */
    font-weight: 400;
    padding: 0 10px;
    color: #4ED5D2 !important;
}

.social-profile ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.social-profile ul li {
    margin: 0 14px;
}

.social-profile ul li a i {
    font-size: 18px;
}

/*-----------------------------
    Modal
-----------------------------*/
.body_overlay {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    background-image: url("https://app.dypius.com/src/assets/bodyBg.png");

    /* transition: 0.3s; */
}

.body_overlay.open {
    visibility: visible;
    opacity: 1;
}

.popup-modal {
    position: fixed;
    width: 600px;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 0 30px;
    border-radius: 7px;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    /* transition: 0.3s; */
}

.popup-modal.open {
    visibility: visible;
    opacity: 1;
    z-index: 999;
}

.popup-header,
.popup-body {
    padding: 0 60px;
}

.popup-header-item,
.popup-body-item,
.popup-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.popup-modal p {
    margin: 0;
}

.popup-header-item p,
.popup-header-item span {
    font-size: 22px;
    /* font-family: "Calibre Medium"; */
    line-height: 28px;
}

.popup-body {
    margin: 40px 0;
}

.popup-body-item p {
    font-size: 18px;
    line-height: 28px;
    opacity: 0.7;
}

.popup-total {
    border-top: 1px solid #434343;
    border-bottom: 1px solid #434343;
    padding: 28px 60px 24px;
}

.popup-total h6,
.popup-total p {
    font-size: 24px;
    line-height: 24px;
    /* font-family: "Calibre Medium"; */
}

.popup-close {
    padding: 18px 60px 0;
    text-align: right;
}

.popup-close button {
    display: inline-block;
    font-size: 20px;
    /* font-family: "Calibre Medium"; */
    line-height: 20px;
    border: none;
    border-radius: 5px;
    padding: 12px 22px 8px;
}

span.badge.badge-light {
    color: #222 !important;
}

span.badge.badge-danger {
    color: #fff !important;
}

.l-table-actions>a>i,
.l-table-actions>a>img {
    width: 22px;
    height: 22px;
    display: inline-block;
    margin: 2px;
}

/* div.l-table-wrapper-div {
  min-height: 500px;
} */

.theme-dark .l-table-wrapper-div {
    background: var(--light-blue);
}

.theme-white .l-table-wrapper-div {
    background: var(--white);
    border-radius: 8px;
}

.theme-dark strong,
.theme-dark h3,
.theme-dark label {
    color: #fff;
}

.theme-white strong,
.theme-white h3,
.theme-white label {
    color: #222;
}

form p {
    margin-bottom: 0.2rem;
}

.sidebar {
    padding-top: 2rem;
}

.logo {
    margin-bottom: 1rem;
}

.home-menu a {
    margin-bottom: 0rem;
}

.dashboard-cards-wrapper {
    width: 49%;
}

.menu-cat-one ul li,
.menu-cat-two ul li {
    margin: 0;
    height: 116px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.theme-dark .l-border-black {
    border: 1px solid var(--black) !important;
}

.theme-dark #search-bar.l-border-black {
    /* background: var(--black) !important; */
}

/* Hide scrollbar */

.sidebar::-webkit-scrollbar,
.l-hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.sidebar,
.l-hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #26264F;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8E97CD;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}



.header-left {
    width: auto;
}

body.theme-white .l-clr-red,
body.theme-dark .l-clr-red {
    color: var(--red) !important;
}

body.theme-white .l-clr-purple,
body.theme-dark .l-clr-purple {
    color: #988ef9 !important;
}

.prediction-item:last-child .suggest-item {
    border-bottom: none !important;
}

body.theme-white .graph-header-left p span.green-text,
body.theme-dark .graph-header-left p span.green-text,
td.green-text {
    color: rgb(87, 214, 67) !important;
}

td.red-text {
    color: var(--red);
}

body {
    line-height: 1;
}

.graph-header-left p {
    font-size: 0.8rem;
}

.btn.v1,
.btn.v2,
.btn.v3 {
    font-size: 0.9rem;
    /* padding-bottom: 2rem!important; */
}

.graph-data {
    margin: 1.2rem 0;
}

.sidebar a.active span,
.sidebar .menu-cat-two.m-0 ul li.activelink a span {
    color: var(--red) !important;
}

.react-datepicker-wrapper {
    min-width: 100%;
}

.testbar {
    background: rgb(3, 3, 3) !important;
    border-right: 1px solid rgb(146 155 152 / 45%);
}

body.theme-white p.text-muted {
    color: #777 !important;
    font-size: 12px;
    /* background: #f5f5f5; */
    border-radius: 6px;
    padding: 10px 10px 10px 0;
}

body.theme-dark p.text-muted {
    color: var(--black2) !important;
    /* background: #f5f5f5; */
    border-radius: 6px;
    padding: 10px;
}

body.theme-dark .table thead th,
body.theme-dark .table tbody td {
    border-color: rgba(81, 81, 81, 1);
}

body.theme-dark .table thead th {
    border-bottom: none;
    /* color: var(--black2); */
    font-weight: normal;
    color: #fff;
}

.table * {
    font-size: 13px;
}

body.theme-dark .table tbody td {
    color: #fff;
}

body.theme-dark input[type="text"],
body.theme-dark input[type="email"],
body.theme-dark input[type="number"],
body.theme-dark select.form-control {
    background: var(--grey);
    color: var(--white);
    border: none;
}

.body.theme-dark .form-control ::placeholder {
    color: rgba(255, 255, 255, 0.8) !important;
    font-size: 12px;
}

.locker input.form-control {
    font-size: 13px;
    padding: 1rem;
}

.base-wrapper {
    border-radius: 6px;
    padding: 10px;
    background: rgba(227, 6, 19, 0.1);
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    justify-content: center;
    width: 168px;
    height: 46px;
}

.balance-placeholder {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
}

.balance-text {
    font-weight: 300;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.018em;
}

.counter-wrapper {
    background: #ec2120;
    border-radius: 4px;
    padding: 5px 10px;
    filter: drop-shadow(0px 7px 14px rgba(236, 33, 32, 0.24));
}

.lock-text-wrapper {
    width: 85%;
}

.counter-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.018em;
    color: #ffffff !important;
    display: flex;
    gap: 10px;
}

.badge-wraper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.claim-btn {
    background: #ffffff !important;
    border-radius: 6px !important;
    width: 103px !important;
    height: 26px !important;
    position: absolute !important;
    left: 72%;
    z-index: 11 !important;
    bottom: 5px !important;
    opacity: 1 !important;
}

.moreinfo-wrapper {
    padding: 10px;
    border: 1px solid #e30613;
    border-radius: 6px;
    cursor: pointer;
}

.moreinfo-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: #e30613 !important;
    display: flex;
    gap: 5px;
    align-items: center;
}

.info-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    max-width: 600px;
    width: 100%;
}

.info-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.018em;
}

.top-locked-wrapper {
    border-radius: 6px 6px 0px 0px;
    padding: 5px 10px;
    position: absolute;
    top: -24px;
}

.top-locked-text {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff !important;
}

.copylink-wrapper {
    background: #f5f5f5;
    border-radius: 6px;
    padding: 12px;
    width: 248px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;
}

.link-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a4a4a4 !important;
    text-align: left;
}

.sharelink-text {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #000;
}

.copy-btn {
    background: linear-gradient(51.32deg, #e30613 -12.3%, #fa4a33 50.14%);
    border-radius: 6px;
    padding: 5px 15px;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
}

.info-wrappers {
    background: #f5f5f5;
    border-radius: 6px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
}

.row-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left-info-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.018em;
    color: rgb(153, 153, 153) !important;
}

.right-info-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: #F7F7FC !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: none !important;
    box-shadow: none !important;
}

/* .rc-slider-handle:focus {
  outline: none;
  box-shadow: none;
}
.rc-slider-handle:focus-visible {
  border-color: #4ED5D2 !important;
  box-shadow: 0 0 0 3px #4ED5D2 !important;
}
.rc-slider-handle-click-focused:focus {
  border-color: #4ED5D2 !important;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #4ED5D2 !important;
}
.rc-slider-handle:active {
  border-color: #4ED5D2 !important;
  box-shadow: 0 0 5px #4ED5D2 !important;
  cursor: -webkit-grabbing;
  cursor: grabbing;
} */

/* .rc-slider-dot-active {
  border-color: #4ED5D2 !important;
  background: #4ED5D2 !important;

}

.rc-slider-dot {
  height: 16px !important;
  width: 16px !important;
  bottom: -8px !important;
  background: #4ED5D2 !important;
  border: #4ED5D2 !important;
} */

.rc-slider-rail {
    /* background: rgba(227, 6, 19, 0.32) !important; */
    background: #8E97CD !important;
    height: 8px !important;
}

.rc-slider-track {
    background: #4ED5D2 !important;
    height: 8px !important;
}

.rc-slider-handle {
    background: #4ED5D2 !important;
    /* border-color: #4ED5D2 !important; */
    color: #4ED5D2 !important;
    opacity: 1 !important;
    width: 40px !important;
    height: 40px !important;
    border: 8px solid white !important;
    bottom: -15px !important;

}

.slider-text-wrapper {
    /* display: flex; */
    /* justify-content: space-between; */
    width: 100%;
    /* margin-top: 15px; */
    position: relative;
}

.slider-text {
    /* display: flex; */
    /* width: 25%; */
    /* justify-content: end; */
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: -0.018em;
    position: absolute;
    color: #C0C9FF !important;
    transition: all .25s;
}

.slider-text:first-child {
    left: -4%;
}

.slider-text:nth-child(2) {
    left: 29%;
}

.slider-text:nth-child(3) {
    left: 63%;
}

.slider-text:nth-child(4) {
    right: -5%;
}


.slider-text-active {
    transform: translateY(-85px);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    padding: 5px 10px;
    background: #1E1C40 !important;
    border-radius: 12px;
    letter-spacing: -0.018em;
    color: #4ED5D2 !important;
}

.first-value {
    left: -7% !important;
}

.second-value {
    left: 26% !important;
}

.third-value {
    left: 60% !important;
}

.fourth-value {
    right: -8% !important;
}



.table-left-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    padding-bottom: 40px;
}

.table-wrapper {
    /* background-image: ;  */
    border-radius: 6px;
    padding: 3px;
}

.single-info {
    display: flex;
    justify-content: end;
    position: relative;
    top: -25px;
    height: 0;
    color: gray;
    cursor: pointer;
}

.status-wrapper {
    border-radius: 6px;
    margin-top: 1rem;
    background: rgba(227, 6, 19, 0.1);
    padding: 10px;
    width: fit-content;
}

.pair-locks-wrapper {
    border-radius: 6px;
    background: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    justify-content: center;
}

.table-title-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: -0.018em;
    color: #ffffff !important;
}

.table-subtitle-text {
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #ffffff !important;
}

.status-btn-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.018em;
    color: #ff4229 !important;
    gap: 5px;
    display: flex;
    align-items: center;
}

.status-button {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    width: 62px;
    height: 18px;
    justify-content: center;
}

.badge-img {
    position: absolute;
    left: 30%;
    z-index: 1;
    bottom: -30px;
}

.search-pair-btn {
    background: linear-gradient(51.32deg, #e30613 -12.3%, #fa4a33 50.14%);
    border-radius: 6px;
    width: 134px;
    height: 42px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholderdiv {
    /* height: 480px; */
    background-image: url(https://app.dypius.com/img/badge-hero.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.search-pair-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff !important;
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
    display: inline-block;
}

.resp-sharing-button__link {
    text-decoration: none;
    color: #fff;
    margin: 0.5em;
}

.resp-sharing-button {
    border-radius: 5px;
    transition: 25ms ease-out;
    padding: 0.5em 0.75em;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.Collapsible {
    position: relative !important;
}

.Collapsible__trigger {
    position: absolute !important;
    bottom: -20px !important;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    color: #e30613 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
}

.firstbox-wrapper {
    background: #ffffff;
    box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    /* max-width: 415px; */
    min-height: 298px;
    width: 100%;
    padding: 20px;
    /* height: 100%; */
    position: relative;
}

.latestnews-wrapper {
    background: #ffffff;
    box-shadow: 12px 16px 44px rgb(0 0 0 / 12%);
    border-radius: 8px;
    padding-bottom: 10px;
}

.firstbox-text {
    font-weight: 500;
    font-size: 12px !important;
    line-height: 12px !important;
    text-align: right;

    color: #999999 !important;
}

.popup-btn {
    position: relative;
    /* bottom: 24px; */
    display: flex;
    justify-content: center;
    /* text-align: center; */
    width: 100%;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #e30613 !important;
    border: 1px solid #e30613;
    border-radius: 0px 0px 8px 8px;
    height: 30px;
    align-items: center;
    /* left: 21px; */
    background: #ffffff;
    gap: 5px;
}

.leftside {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 34%;
    /* justify-content: space-between; */
}

.rightside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 19px;
    width: 64%;
}

.graph-progress {
    display: flex;
    justify-content: space-between;
}

.color-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.singlepress-inner {
    flex-wrap: inherit;
}

.tradebtn {
    background: linear-gradient(51.32deg, #e30613 -12.3%, #fa4a33 50.14%);
    border-radius: 6px;
    outline: none !important;
    border: none !important;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #ffffff !important;
    width: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.lockliqtext {
    width: 85%;
}

@media only screen and (max-width: 1420px) {
    /* .secondbox-wrapper {
    max-width: 100% !important;
  } */

    /* .favorites {
    width: 100% !important;
  } */
}

.graph-right2 {
    display: none;
}

@media only screen and (max-width: 1492px) {
    /* .social-and-links{
    flex-direction: column !important;
    align-items: flex-end !important;
  } */

    .leftside {
        width: 39% !important;
    }

    .rightside {
        width: 59% !important;
    }
}

@media only screen and (max-width: 1348px) {
    .graph-wrap {
        flex-direction: column !important;
    }

    .graph-right {
        display: none !important;
    }

    .graph-right2 {
        display: block !important;
    }

    .leftside {
        width: 100% !important;
    }

    .firstbox-wrapper {
        max-width: 100% !important;
    }

    .rightside {
        width: 100% !important;
    }
}

.firstbox-inner {
    background: #f7f7fc;
    border-radius: 8px;
    padding: 20px;
}

.firstbox-title {
    font-weight: 500;
    font-size: 12px !important;
    line-height: 15px;
    letter-spacing: -0.018em;
    color: #303134 !important;
}

.favRow {
    border-radius: 8px;
    padding: 10px;
    background: rgba(195, 202, 217, 0.2);
}

.favliq {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: -0.018em;

    color: #303134;
}

.favpair {
    font-weight: 400;
    font-size: 13px !important;
    line-height: 12px !important;
    letter-spacing: -0.018em;

    color: #71757e;
}

.resp-sharing-button__icon svg {
    width: 1em;
    height: 1em;
    margin-right: 0.4em;
    vertical-align: top;
}

.resp-sharing-button--small svg {
    margin: 0;
    vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
    stroke: #fff;
    fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
    fill: #fff;
    stroke: none;
}

.resp-sharing-button--twitter {
    background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
    background-color: #2795e9;
}

.resp-sharing-button--pinterest {
    background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
    background-color: #8c0615;
}

.resp-sharing-button--facebook {
    background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
    background-color: #2d4373;
}

.resp-sharing-button--tumblr {
    background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
    background-color: #222d3c;
}

.resp-sharing-button--reddit {
    background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
    background-color: #3a80c1;
}

.resp-sharing-button--google {
    background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
    background-color: #c23321;
}

.resp-sharing-button--linkedin {
    background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
    background-color: #046293;
}

.resp-sharing-button--email {
    background-color: #777;
}

.resp-sharing-button--email:hover {
    background-color: #5e5e5e;
}

.resp-sharing-button--xing {
    background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
    background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
    background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
    background-color: #1da851;
}

.resp-sharing-button--hackernews {
    background-color: #ff6600;
}

.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
    background-color: #fb6200;
}

.resp-sharing-button--vk {
    background-color: #507299;
}

.resp-sharing-button--vk:hover {
    background-color: #43648c;
}

.resp-sharing-button--twitter {
    background-color: #55acee;
    border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
    background-color: #2795e9;
    border-color: #2795e9;
}

.resp-sharing-button--reddit {
    background-color: rgb(255, 69, 0);
    border-color: rgb(255, 69, 0);
}

.resp-sharing-button--reddit:hover,
.resp-sharing-button--reddit:active {
    background-color: rgb(221, 68, 12);
    border-color: rgb(221, 68, 12);
}

.resp-sharing-button--telegram {
    background-color: #54a9eb;
}

.resp-sharing-button--telegram:hover {
    background-color: #4b97d1;
}

.social-share-wrapper-div {
    position: absolute;
    display: none;
    background: var(--white);
    padding: 1rem;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    left: -15px;
    z-index: 3;
}

.theme-dark .social-share-wrapper-div {
    background: var(--black);
    box-shadow: 2px 2px 6px rgba(255, 255, 255, 0.2);

    /* border: 1px solid #666; */
}

.social-share-parent:hover .social-share-wrapper-div {
    display: block;
}

.is-favorite {
    background: transparent !important;
}

.progress>div {
    transition: width 400ms;
}

.modal .table td,
.table th {
    border-top: none;
    color: #fff;
}

.theme-dark .modal-content {
    background-color: #1a1a36;
    color: #fff;
    overflow-y: scroll;
}

.modal-content {
    border-radius: 8px;
    padding: 20px;
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121  !important;
    
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 800px;
    height: fit-content;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 50%;
}

.theme-dark .modal-footer {
    border-top: 1px solid #434343;
}

.theme-dark .modal-header {
    border-bottom: none;
    padding: 0 !important;
}

.theme-dark .modal-body {
    padding: 0 !important;
}

.MuiTimelineItem-missingOppositeContent:before {
    content: none;
}

span.l-return-type {
    padding: 6px;
    border-radius: 4px;
    margin: 0 6px;
    background: rgba(0, 0, 0, 0.05) !important;
    display: inline-block;
}

body.theme-dark span.l-return-type {
    background: rgba(255, 255, 255, 0.2) !important;
}

.l-pair-explorer .rdt_TableRow {
    /* display: inline-block; */
    /* margin: 0 0.5rem; */

    animation: fadeInDown;
    /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s;
    /* don't forget to set a duration! */
}

.mobile-footer-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #F7F7FC !important;

}

@media screen and (max-width: 786px) {
    .external-links {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
        column-gap: 40px !important;
        row-gap: 20px !important;
    }
}

@media all and (max-width: 992px) {
    .bridged {
        display: none;
    }

    .header-right {
        display: none;
    }

    /* .right-content {
    margin-top: 80px !important;
  } */
}

.disabled-btn {
    color: #c0c9ff !important;
    background: rgb(0, 44, 58) !important;
    border: none !important;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    padding: 9px 24px;
    font-size: 12px;
    line-height: 18px;
    opacity: 1 !important;
}

.success-button {
    color: #ffffff !important;
    background: linear-gradient(90.74deg, #75cac2 0%, #57b6ab 100%) !important;
    border: none !important;
    border-radius: 8px;
    opacity: 1 !important;

}

.fail-button {
    color: #ffffff !important;
    background: linear-gradient(90.74deg, #f8845b 0%, #f0603a 100%) !important;
    border: none !important;
    border-radius: 10px;
    opacity: 1 !important;
}

.stats-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}

.stats-card {
    /* background: rgb(3, 3, 3) !important; */
    border: 1px solid white;
    border-radius: 8px;
    height: 100px;
}

.stats-card-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: white !important;
}

.stats-card-content {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.018em;
    color: #F7F7FC;
}

.stats-usd-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.018em;
    color: #4ED5D2 !important;

}

.stats-link {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #F77F5A;
}

.stats-link:hover {
    color: #F77F5A !important;
    text-decoration: underline;
}

.buyback-coin {
    background: #3A377A;
    border: 1px solid #565891;
    border-radius: 8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-container {
    position: relative;
}

.label {
    position: absolute;
    left: 10px;
    top: 12px;
    transition: all 0.2s;
    padding: 0 2px;
    z-index: 1;
    color: #f7f7fc !important;
    font-size: 12px;
    cursor: text;
    pointer-events: none;
}

.secondary-label {
    color: #b3b3b3 !important;

}

.separator {
    background: white !important;
}

.text-input {
    padding: 0.4rem;
    /* width: 100%; */
    /* height: 100%; */
    border: 1px solid rgb(146 155 152 / 50%) !important;

    /* background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121  !important; */
    border-radius: 8px;
    outline: none;
    transition: all .2s;
    color: #f7f7fc !important;
}

.chain-content {
    border: 1px solid rgb(146 155 152 / 50%) !important;
    background:  linear-gradient(180deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%) .01%, rgb(0 0 0 / 15%)), #212121  !important;
}

.bnb-chain-wrapper {
    background-image: none;
}

.label {
    position: absolute;
    left: 10px;
    top: 8px;
    transition: all 0.2s;
    padding: 0 2px;
    z-index: 1;
    color: #f7f7fc !important;
    font-size: 14px;
    cursor: text;
}


.text-input:focus+.label,
.text-input:not(:placeholder-shown)+.label,
.filled {
    top: -20px;
    font-size: 12px;
}

.text-input::placeholder {
    opacity: 0;
    transition: all .3s;
}

.text-input:focus::placeholder {
    opacity: 1;
    animation-delay: 0.2s;
}

@media screen and (max-width: 786px) {
    .dashboard-cards-wrapper {
        width: 100%;
    }

    .subscr-price {
        font-size: 20px;
        white-space: pre;
    }

    .stats-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .modal-content {
        width: 100%;
    }

    .social-profile ul {
        justify-content: start;
        gap: 10px;
    }

    .social-profile ul li {
        margin: 0 3px;
    }

    .footer-menu ul {
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        gap: 10px;
    }


    .footer-menu ul li a {
        padding: 0px !important;
    }

    .social-and-links {
        align-items: flex-start !important;
    }

    .footer {
        padding-bottom: 70px;
    }
}

.gradient-title {
    background: #FFA812;
    background: linear-gradient(to left, #FFA812 0%, #70FF29 42%, #67FF5C 50%, #9ad6f2 86%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
}

.main-panel {
    flex-grow: 100;
    width: 100%;
}

.row-space {
    padding-top: 24px !important;
}